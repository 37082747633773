import React from "react";
import { 
  Flex,
  Stack, 
  Button, 
  Image,
  useColorMode, 
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb
 } from "@chakra-ui/react";
import { BsPeopleFill, BsVolumeUpFill, BsVolumeMuteFill } from 'react-icons/bs';
import { GiOpenBook } from 'react-icons/gi'
import { CgSearchLoading } from 'react-icons/cg';
import { TiInfoLargeOutline } from 'react-icons/ti'
import { FaVoteYea, FaHandshake, FaMoon, FaSun, FaFlag } from 'react-icons/fa';
import { BiHelpCircle } from 'react-icons/bi';

const SLIDER_RANGE = 100;

const Menu = props => {
  const { 
    isBasicInfoReady = false, 
    isActiveTab = '', 
    onClick, 
    bgVolume,
    setBgVolume
  } = props;

  const { colorMode, toggleColorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";
  const bgColor = useColorModeValue("white", "gray.800");

  const isBgMusicMute = bgVolume === 0;

  const handleVolumeChange = val => {
    setBgVolume(val / SLIDER_RANGE)
  }

  return (
    <Stack h="100%" spacing={4} p={2} bg={bgColor} borderRightWidth="1px" justify="space-between">
      <Flex w="100%" flexDirection="column" justify="space-between">
        <Image w="88px" src="/menu-logo.png" />
        <Button 
          my={1}
          colorScheme="red" 
          variant="ghost" 
          leftIcon={<TiInfoLargeOutline />} 
          isActive={isActiveTab === "info"}
          value="info"
          onClick={onClick}
        >
          基本
        </Button>
        <Button 
          my={1}
          colorScheme="red" 
          variant="ghost" 
          leftIcon={<BsPeopleFill />} 
          isActive={isActiveTab === "players"}
          value="players"
          isDisabled={!isBasicInfoReady}
          onClick={onClick}
        >
          玩家
        </Button>
        <Button 
          my={1}
          colorScheme="red" 
          variant="ghost" 
          leftIcon={<GiOpenBook />} 
          isActive={isActiveTab === "scenes"}
          isDisabled={!isBasicInfoReady}
          value="scenes"
          onClick={onClick}
        >
          環節
        </Button>
        <Button 
          my={1}
          colorScheme="red" 
          variant="ghost" 
          leftIcon={<CgSearchLoading />} 
          isActive={isActiveTab === "clues"}
          isDisabled={!isBasicInfoReady}
          value="clues"
          onClick={onClick}
        >
          線索
        </Button>
        <Button 
          my={1}
          colorScheme="red" 
          variant="ghost" 
          leftIcon={<FaFlag />} 
          isActive={isActiveTab === "actions"}
          isDisabled={!isBasicInfoReady}
          value="actions"
          onClick={onClick}
        >
          行動
        </Button>
        <Button 
          my={1}
          colorScheme="red" 
          variant="ghost" 
          leftIcon={<FaVoteYea />} 
          isActive={isActiveTab === "votes"}
          isDisabled={!isBasicInfoReady}
          value="votes"
          onClick={onClick}
        >
          投票
        </Button>
        <Button 
          my={1}
          colorScheme="red" 
          variant="ghost" 
          leftIcon={<FaHandshake />} 
          isActive={isActiveTab === "endings"}
          isDisabled={!isBasicInfoReady}
          value="endings"
          onClick={onClick}
        >
          結局
        </Button>
      </Flex>
      <Stack>
        <Popover placement="right">
          <PopoverTrigger>
            <Button 
              my={1}
              colorScheme="red" 
              variant="ghost" 
              leftIcon={isBgMusicMute ? <BsVolumeMuteFill />: <BsVolumeUpFill />} 
            >
            音量
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <Slider colorScheme="red" aria-label="slider-ex-1" value={bgVolume * SLIDER_RANGE} onChange={handleVolumeChange}>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <Button 
          my={1}
          colorScheme="red" 
          variant="ghost" 
          leftIcon={isDarkMode ? <FaSun />: <FaMoon />} 
          onClick={toggleColorMode}
        >
          {isDarkMode ? "淺色" : "深色"}
        </Button>
        <Button 
          my={1}
          colorScheme="red" 
          variant="ghost" 
          leftIcon={<BiHelpCircle />} 
          isActive={isActiveTab === "help"}
          value="help"
          onClick={onClick}
        >
          說明
        </Button>
      </Stack>
    </Stack>
  )
};

export default Menu;