import React from "react";
import {
  Stack,
  Heading, 
  Text,
  Divider,
  ListItem, 
  OrderedList, 
} from "@chakra-ui/react";

const Help = () => {

  return (
    <Stack p={4}>
      <Stack>
        <Heading size="lg">主持人介面使用說明與秘訣</Heading>
        <OrderedList p={4} spacing={4}>          
          <ListItem>主持端與玩家端無法在同一個瀏覽器同時開啟，<Text as="span" color="red.500">若需要開玩家端，請開另一個瀏覽器（若是使用 Chrome ，不同使用者會被視為不同瀏覽器）</Text></ListItem>
          <ListItem>「玩家」：可以看到在此 LARP Bar 房間中的有登錄的玩家，請留意是否進入 LARP Bar 的玩家數量是否與角色數量一致。</ListItem>
          <ListItem>「玩家」：所有遊戲開始前一定都要先按「開始選角」，<Text as="span" color="red.500">還沒選角無法「進入」任一環節！</Text></ListItem>
          <ListItem>「玩家」：玩家若在遊戲開始後因為更換裝置而重新進入遊戲卻顯示遊戲已開始，主持人可以點擊場上玩家（有扮演角色）的名稱替換為不在場上的玩家，原本場上的玩家會馬上被踢出遊戲，而場下的玩家會上場並且繼承資料。</ListItem>
          <ListItem>「玩家」：點擊角色的「剩餘探索次數」可以修改該名角色的探索次數。</ListItem>
          <ListItem>「環節」：<Text as="span" color="red.500">開始選角後，才可以進入第一個環節。</Text>選角結束不必手動關閉選角視窗，直接「進入」第一環節即會關閉選角視窗。</ListItem>
          <ListItem>「線索」：【指定蒐證角色】需要在特定環節中使用，需要在上傳劇本資料時做設定，請確認是需要使用的環節才使用。</ListItem>
          <ListItem>「線索」：可以透過【類別】或是【持有人】來篩選持有狀況。</ListItem>
          <ListItem>「線索」：主持人指派線索無視各種限制條件。</ListItem>
          <ListItem>「行動」：可以看到玩家【使用】過的線索紀錄。</ListItem>
          <ListItem>「投票」：選單可以透過【回答者】或是【題目】來排序。</ListItem>
          <ListItem>「結局」：有分配結局功能的劇本才用得到。</ListItem>
          <ListItem>「基本」：若該局遊戲剛開始沒多久就遇到無法正常解決的問題，請按【重新來過】，會清除該局資料，主持人與玩家都會被剔除，主持端會自動重新整理，玩家端需手動重新整理，重整完即可重新加入遊戲。</ListItem>
          <ListItem>「基本」：若該局遊戲已分配完結局並講解完畢，請按【打卡下班】，主持人與玩家均無法再進行任何操作，重新整理也無法再進入遊戲。</ListItem>
          <ListItem>使用上有遇到問題或者任何建議請反映給相關人員，或者使用「基本」中的【問題回報】，感謝～</ListItem>
        </OrderedList>
      </Stack>
      <Divider/>
      <Stack>
        <Heading size="lg">玩家疑難雜症</Heading>
        <OrderedList p={4} spacing={4}>
          <ListItem><Text as="span" color="red.500">LARP Bar 無法在無痕瀏覽下正常使用！凡是玩家有瀏覽器問題請一律建議使用 Chrome 的「訪客模式」</Text>（從瀏覽器右上角的大頭照進入）</ListItem>
          <ListItem><Text as="span" color="red.500">訪客模式與無痕瀏覽是不同的！</Text>訪客模式與無痕瀏覽是不同的！訪客模式與無痕瀏覽是不同的！</ListItem>
          <ListItem>如果玩家使用無痕模式，玩家在啟動遊戲時會出現「您目前使用無痕模式/私密瀏覽」的錯誤訊息。請建議改用 Chrome 訪客模式。</ListItem>
          <ListItem>開場前請提醒玩家可以切換深色／淺色模式，電腦版在左下角，手機版在右上角。</ListItem>
          <ListItem>電腦版的背景音樂會自動播放音樂，如果有玩家使用手機版說沒有音樂，請按右上角的播放鍵。（手機對於自動播放有嚴格限制）</ListItem>
          <ListItem>任何一本劇本都可以在遊戲開始前請玩家探索 <Text as="span" color="red.500">888</Text> 來嘗試使用線索功能，開始選角後會自動清掉這條線索，而且玩家也無法在遊戲開始後使用 888 獲得線索。</ListItem>
          <ListItem>玩家端可以看到多數操作說明，可以建議玩家在遊戲中遇到操作問題時先看說明。</ListItem>
          <ListItem>玩家若遇到劇本或者線索消失等狀況，通常重新整理 (Windows 按 F5, mac 按 cmd + R) 可以解決。</ListItem>
          <ListItem>玩家在同一個瀏覽器分頁中，斷線重連或者重新整理不需要再次輸入使用者名稱即可回復資料。</ListItem>
          <ListItem>玩家若更換瀏覽器或者裝置回到遊戲，會需要輸入「新的」名字，但輸入完會先被擋在房門外無法進入，<Text as="span" color="red.500">請在主持人的「玩家」選單中找到該玩家的新名稱，並且換角才能夠讓玩家回到遊戲。</Text></ListItem>
          <ListItem>遊戲開始後，玩家使用不同瀏覽器或者裝置回到遊戲，會同步劇本與線索，但不會同步筆記與收藏。</ListItem>
        </OrderedList>
      </Stack>
      <Divider/>
      <Stack>
        <Heading size="lg">Gather Town 內探索（若此劇本不支援，可忽略此段）</Heading>
        <OrderedList p={4} spacing={4}>
          <ListItem>Gather Town 內探索功能有以下使用限制，請親愛的主持人留意：</ListItem>
          <ListItem>如果此劇本支援 Gather Town 內探索，請玩家<Text as="span" color="red.500">一定要在同一個瀏覽器中（Chrome 的同一個使用者）開啟 LARP Bar 玩家端與 Gather Town </Text>，開啟的先後順序無妨。</ListItem>
          <ListItem>也就是說， Gather Town 與 LARP Bar 應該要出現在同一瀏覽器的相鄰分頁。確實進入 LARP Bar 後為了方便瀏覽，可以將其中一個分頁以拖曳的方式變成獨立新視窗，兩者並排。</ListItem>
          <ListItem>只要有在同一個瀏覽器內同時開啟 Gather Town 與 LARP Bar，後續即使不小心關閉其中一個分頁，重新打開依然可以正常使用。</ListItem>
          <ListItem>因為兩個分頁必須在同一個瀏覽器內打開，所以若要使用訪客模式的話<Text as="span" color="red.500">請建議玩家連同 Gather Town 與 LARP Bar 一起在「訪客模式」中打開。 </Text>不可以用「無痕瀏覽」，詳見下方玩家疑難雜症第1點。</ListItem>
          <ListItem>若沒有在同一個瀏覽器內開啟這兩個分頁，玩家在 Gather Town 中探索會看到「你尚未加入遊戲，請先啟動 LARP Bar ！」的錯誤訊息。</ListItem>
          <ListItem>不論此劇本是否支援 Gather Town 的探索，原本的編號探索以及分享功能都依然可以正常使用。因此，若是玩家因為設備問題而必須使用不同瀏覽器或者使用手機開啟 LARP Bar ，雖然無法進行 Gather 內探索，但依然可以在其他玩家以及主持人協助下繼續進行。</ListItem>
          <ListItem>若玩家名稱顯示為奇怪的英文數字組合，請該名玩家使用登出功能（在玩家端的「說明」中第14點），並且請主持人進行角色資料轉移。</ListItem>
        </OrderedList>
      </Stack>
    </Stack>
  )
};

export default Help;