import React from "react";
import {
  Flex, 
  Stack,
  HStack, 
  Box,
  Button, 
  Heading, 
  Text,
  Image,
  Input, 
  InputGroup, 
  InputRightElement, 
  Divider,
  Link,
  Icon
} from "@chakra-ui/react";
import { BiLinkExternal, BiCopyAlt } from 'react-icons/bi';
import { trackEvent } from '../../utils';

const Info = props => {
  const { 
    scenarioId = '',
    roomName = '', 
    platform = '',
    platformUrl = '', 
    platformPw = '', 
    isBasicInfoReady = false, 
    staticData,
    handleToastShow
  } = props;

  const { title, providerName, providerUrls, mainImgSrc } = staticData;

  const handleUrlClick = (urlName) => {
    trackEvent('outbound_link_click', {
      scenario_id: scenarioId,
      target_value: urlName,
      event_category: urlName,
      event_label: `${providerName}-${title}`
    })
  };

  const renderBasicInfo = () => {
    return (
      <Stack>
        <Heading as="h2" size="lg">今日上映：《{title}》</Heading>
        <Text>【{providerName}】隆重鉅獻</Text>
        {providerUrls && providerUrls.map(urlObj => {
          return (
            <Link mx={2} href={urlObj.url} isExternal onClick={() => handleUrlClick(urlObj.name)} key={urlObj.url}>
              {urlObj.name} <Icon as={BiLinkExternal} mx="2px" />
            </Link>
          )
        })}
      </Stack>
    )
  };

  const renderMainImg = () => {
    if (mainImgSrc) {
      return (
        <Box ml={4} flex="1" maxW="600px" maxH="90vh">
          <Image 
            w="100%"
            maxH="100%"
            fit="contain"
            src={mainImgSrc}
          />
        </Box>
      )
    }
  };

  const handleOpenExternal = url => {
    trackEvent('outbound_link_click', {
      scenario_id: scenarioId,
      target_value: platform,
      event_category: platform,
      event_label: `${providerName}-${title}`
    })
    window.open(url);
  }

  const handleCopy = async id => {
    trackEvent('text_copy', {
      scenario_id: scenarioId,
      target_value: platform,
      event_category: platform,
      event_label: `${providerName}-${title}`
    })
    const element = document.getElementById(id);
    try {
      await navigator.clipboard.writeText(element.value);
      handleToastShow('COPY_SUCCESS');
    } catch (err) {
      console.debug(err);
    }
  };

  return (
    <Flex p={4} flexDirection={{ base: "column", sm: "row" }}>
      <Stack spacing={4}>
        <Heading as="h1" size="lg">遊戲房間：{roomName}</Heading>
        <Text>請搭配 {platform} 進行本次遊戲</Text>
        <HStack>
          <InputGroup w="fit-content" onClick={() => handleCopy('platformUrl')}>
            <Input id="platformUrl" variant="filled" value={platformUrl} isReadOnly />
            <InputRightElement children={<BiCopyAlt />}  />
          </InputGroup>
          <Button 
            variant="solid" 
            colorScheme="twitter" 
            rightIcon={<BiLinkExternal />}
            onClick={() => handleOpenExternal(platformUrl)} 
          >
            開新分頁
          </Button>
        </HStack>
        {platformPw && (
          <Stack>
            <Text>Password: </Text>
            <InputGroup w="fit-content" onClick={() => handleCopy('platformPw')}>
              <Input id="platformPw" variant="filled" value={platformPw} isReadOnly />
              <InputRightElement children={<BiCopyAlt />}  />
            </InputGroup>
          </Stack>
        )}
        <Divider />
        {isBasicInfoReady && renderBasicInfo()}
      </Stack>
      {isBasicInfoReady && renderMainImg()}
    </Flex>
  )
};

export default Info;