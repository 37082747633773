import React, { useRef } from "react";
import { 
  Button,
  Input,
  Text,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, 
} from "@chakra-ui/react";
import { BiSearchAlt } from 'react-icons/bi';

const AnswerDialog = props => {
  const { 
    answer, 
    dialogType, 
    explorableCount, 
    onClose, 
    onInput, 
    onToggle,
    onSubmit, 
    isKeepOpening,
    isLoading,
    hasStarted
  } = props;
  const initialRef = useRef(null);

  const isTypeExplore = dialogType === "explore";

  const isSubmitDisabled = hasStarted ? !answer : answer !== "888";

  const handleKeyDown = e => {
    if (isSubmitDisabled) return;
    
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <Modal 
      isOpen={true} 
      onClose={onClose}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      initialFocusRef={initialRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>輸入密碼</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isTypeExplore && <Text>你目前有 {explorableCount} 次探索的機會</Text>}
          <Input 
            ref={initialRef}
            size="lg" 
            variant="outline" 
            value={answer}
            onChange={onInput}
            onKeyDown={handleKeyDown}
          />
          <Text color="red.500">若調查失敗， 15 秒後才可再次調查。</Text>
        </ModalBody>
        <ModalFooter justifyContent={isTypeExplore ? "space-between" : "flex-end"}>
          {isTypeExplore && (
            <Checkbox 
              colorScheme="orange"
              isChecked={isKeepOpening}
              isDisabled={isLoading}
              onChange={onToggle}
            >
              調查成功不關閉視窗
            </Checkbox>
          )}
          <Button 
            rightIcon={<BiSearchAlt />}  
            colorScheme="orange" 
            borderRadius={20}
            variant="solid" 
            onClick={onSubmit} 
            isDisabled={isSubmitDisabled}
            isLoading={isLoading}
          >
            調查
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AnswerDialog;

