import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Box, Center, VStack, Image, Heading, Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { initFb, auth } from '../firebase';
import { STORAGE_BASE, getRoomId, getClue } from '../api';
import { createMarkup, trackEvent } from '../utils';
import { LoadingSpinner } from '../Host/components';
import { sampleClueMap } from '../Room/constants';

const SUCCESS_STATUS = ["SUCCESS", "ALREADY_HAVE"];

const ClueExplorer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [clueData, setClueData] = useState(null);
  const [mediaSrc, setMediaSrc] = useState("");
  const [alertType, setAlertType] = useState("");
  const ytRef = useRef(null);
  const { scenarioId, clueId } = useParams();

  useEffect(() => {
    if (clueId === "888") {
      const hasSampleClueExplored = window.localStorage.getItem("hasSampleClueExplored");
      window.localStorage.setItem("hasSampleClueExplored", "true");

      setClueData(sampleClueMap["888"])
      setAlertType(hasSampleClueExplored ? "ALREADY_HAVE" : "SUCCESS")
      setIsLoading(false);
    } else if (scenarioId && clueId) {
      trackEvent("embed_view", {
        scenario_id: scenarioId,
        target_type: "clue",
        target_value: clueId
      });

      setIsLoading(true);

      initFb().then(() => {
        auth.onAuthStateChanged(user => {
          if (user && user.uid) {
            getRoomId().then(result => {
              const roomId = result.data.roomId;
    
              if (roomId !== "") {
                getClue({
                  scenarioId,
                  clueId,
                  isEmbed: true,
                  roomId
                })
                .then(res => {
                  setClueData(res.data[0])
        
                  if (SUCCESS_STATUS.includes(res.data[0].status)) {
                    setMediaSrc(`${STORAGE_BASE}/${scenarioId}/clues/${res.data[0].fileName}`);
                  }
                  
                  setAlertType(res.data[0].status)
                  setIsLoading(false);
                })
                .catch(error => {
                  console.log(error);
                  Sentry.captureException(error);
                  setIsLoading(false);
                  setAlertType("ERROR")
                })
              } else {
                setIsLoading(false);
                setAlertType("NO_ROOM_ID")
              }
            })
          }
        })
      })
      .catch(error => {
        console.log(error);
        Sentry.captureException(error);
        setIsLoading(false);
        setAlertType("ERROR")
      })
    }
  }, [scenarioId, clueId]);

  useEffect(() => {
    if (ytRef.current) {
      ytRef.current.style.width = "100%";
    }
  }, [ytRef.current]);

  const getAlertText = (alertType, options = {}) => {
    const { isDiggable, isDecodable } = options;
    let alertStatus = "", alertText = "";
    const actionText = `進行檢視${isDiggable ? "、深入調查" : ""}${isDecodable ? "、解碼" : ""}等行動`

    switch (alertType) {
      case "SUCCESS":
        alertStatus = "success";
        alertText = `獲得新線索！此線索已收入你的 LARP Bar 線索夾，你可以在 LARP Bar 中${actionText}。`
        break;
      case "ALREADY_HAVE":
        alertStatus = "success";
        alertText = `你已擁有此線索！你可以在 LARP Bar 中${actionText}。`
        break;
      case "NOT_EXPLORABLE_SCENE":
        alertStatus = "warning";
        alertText = "現在不是四處調查的時候！晚點再來看看吧！"
        break;
      case "NOT_AVAILABLE_NOW":
        alertStatus = "warning";
        alertText = "現階段無法看出這裡有什麼東西，晚點再來看看吧！"
        break;
      case "EXPLORALE_COUNT_INSUFFICIENT":
        alertStatus = "warning";
        alertText = "你沒有足夠的探索次數了，要不要請其他人來調查看看呢？"
        break;
      case "REACH_LIMIT":
        alertStatus = "warning";
        alertText = "限量是殘酷的！這裡的東西已經被其他人拿光啦！"
        break;
      case "NOT_IN_GAME_TIME":
        alertStatus = "error";
        alertText = "遊戲尚未開始或者已經結束。"
        break;
      case "CHRACTER_NOT_DISTRIBUTED":
        alertStatus = "error";
        alertText = "還沒被分配到劇中角色嗎？請洽主持人。"
        break;
      case "NO_ROOM_ID":
        alertStatus = "error";
        alertText = "你尚未加入遊戲，請先啟動 LARP Bar ！"
        break;
      default:
        alertStatus = "error";
        alertText = "發生不明錯誤，請稍後再試。"
    }

    return { 
      alertStatus,
      alertText
    }
  };

  if (isLoading) {
    return <LoadingSpinner isLogo />
  } else if (!SUCCESS_STATUS.includes(alertType)) {
    const { alertStatus, alertText } = getAlertText(alertType);

    return (
      <Center w="100vw" h="100vh">
        <Alert
          w="60%"
          status={alertStatus}
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            {alertText}
          </AlertTitle>
        </Alert>
      </Center>
    )
  } else {
    const { title, fileType, ytId, content, isDiggable, isDecodable } = clueData;

    const isShowImage = (!fileType || fileType === "image") && mediaSrc !== "";
    const isShowAudio = fileType === "audio" && mediaSrc !== "";
    const isShowVideo = fileType === "video" && mediaSrc !== "";
    const isShowYoutube = fileType === "youtube" && ytId !== "";

    const { alertStatus, alertText } = getAlertText(alertType, { isDiggable, isDecodable });

    return (
      <VStack p={3}>
        <VStack w={{ base: "100%", md: "48em" }}>
          <Alert status={alertStatus} variant="left-accent">
            <AlertIcon />
            {alertText}
          </Alert>
        </VStack>
        <VStack 
          w={{ base: "100%", md: "48em" }}
          minH="300px" 
          mb={5}
          p={{ base: 3, md: 5 }} 
          spacing={1} 
          justify="space-between"
          shadow="md" 
          borderWidth="1px" 
          borderRadius={4} 
        >
          {(isShowImage || isShowAudio || isShowVideo || isShowYoutube) && (
            <Box w="100%" maxW="32em">
              {isShowImage && mediaSrc && (
                <Image
                  boxSize="100%"
                  fit="contain"
                  src={mediaSrc}
                  alt={title}
                />
              )}
              {isShowAudio && <audio src={mediaSrc} controls style={{ margin: "auto "}} />}
              {isShowVideo && <video src={mediaSrc} controls />}
              {isShowYoutube && (
                <iframe 
                  id="ytplayer" 
                  ref={ytRef}
                  type="text/html" 
                  width="500" 
                  height="281"
                  src={`https://www.youtube-nocookie.com/embed/${ytId}?rel=0&cc_load_policy=1&fs=1&color=white&iv_load_policy=3`}
                  frameBorder="0" 
                  allowFullScreen
                />
              )}
            </Box>
          )}
          <Heading as="h3" align="center">
            {title || ""}
          </Heading>
          {content && (
            <p
              className={`room-clue-content`} 
              dangerouslySetInnerHTML={createMarkup(content)} 
            />
          )}
        </VStack>
      </VStack>
    )
  }
};

export default ClueExplorer;