import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react"
import { theme } from './theme';
import { ClueExplorer } from './Embed';
import { LoadingSpinner } from './Host/components';
import './App.css';

const Room = React.lazy(() => import('./Room/Room'));
const Host = React.lazy(() => import('./Host/Host'));
const Admin = React.lazy(() => import('./Admin/Admin'));
const Home = React.lazy(() => import('./Home/Home'));
const Logout = React.lazy(() => import('./Logout'))

function App() {
  return (
    <div className="App">
      <ChakraProvider theme={theme}>
        <Suspense fallback={<LoadingSpinner isLogo />}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/r/:roomId?" render={() => <Room />} />
              <Route exact path="/host/:roomId?" render={() => <Host />} />
              <Route exact path="/admin/:logout?" render={() => <Admin />} />
              <Route exact path="/ec/:scenarioId/:clueId" render={() => <ClueExplorer />} />
              <Route exact path="/logout" render={() => <Logout />} />
              <Route path="/" render={() => <Home />} />
            </Switch>
          </BrowserRouter>
        </Suspense>
      </ChakraProvider>
    </div>
  );
}

export default App;
