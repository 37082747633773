import React, { useMemo, useState, useRef, useEffect } from "react";
import { 
  Flex, 
  VStack, 
  Box, 
  Text, 
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
  useColorModeValue
} from "@chakra-ui/react";
import { FaLightbulb, FaRegLightbulb } from 'react-icons/fa';
import { RiMarkPenFill, RiFileListLine } from 'react-icons/ri';
import { Votes, KeywordInput } from './';
import { createMarkup, getSelectedText, getContentWithKeyword, trackEvent } from '../../utils';

const Scenes = props => {
  const { 
    scenarioId = '',
    isAboveSm,
    currentSceneId = '',
    sceneIdList = [], 
    sceneMap = {},
    voteDataList = [], 
    selectedScene = '',
    onSelectScene = () => {},
    onReady = () => {},
    isReady = false,
    readProgress = 0,
    onReadProgress,
    onOptionSelect = () => {},
    handleToastShow = () => {},
    answerMap = {},
    setAnswerMap = () => {},
    findKeyword = '',
    setFindKeyword = () => {},
  } = props;
  const [isShowPopover, setIsShowPopover] = useState(false);
  const [popoverPostion, setPopoverPosition] = useState([0, 0]);
  const selectedTextRef = useRef(null);

  const isAboveMd = useBreakpointValue({ base: false, md: true });
  const bgColor = useColorModeValue("white", "gray.800");

  const highlightInStorage = JSON.parse(localStorage.getItem("highlight")) || [];

  useEffect(() => {
    if (!isAboveSm) {
      document.addEventListener("selectionchange", handleTextSelect)

      return () => {
        document.removeEventListener("selectionchange", handleTextSelect)
      }
    }
  }, [isAboveSm])

  const sortedSceneIdList = useMemo(() => {
    return sceneIdList
      .filter(id => sceneMap[id].type !== "SEARCH")
      .sort((a, b) => sceneMap[a].order - sceneMap[b].order)
  }, [sceneIdList])

  const handleTextSelect = e => {
    const selectedText = getSelectedText();
    if (selectedText) {
      setIsShowPopover(true);
      if (e.pageX && e.pageY) {
        setPopoverPosition([e.pageX, e.pageY]);
      }
      selectedTextRef.current = selectedText;
    } else {
      setIsShowPopover(false);
    }
  };

  const handleHighlightSave = () => {
    const newHighlightData = {
      source: "scenes",
      sceneId: selectedScene,
      text: selectedTextRef.current
    }

    const newHighlight = [...highlightInStorage, newHighlightData];
    localStorage.setItem("highlight", JSON.stringify(newHighlight));

    setIsShowPopover(false);
    selectedTextRef.current = '';
    handleToastShow("HIGHLIGHT_SAVE_SUCCESS")

    trackEvent('text_highlight', {
      scenario_id: scenarioId
    });
  };

  const getHighlightContent = (content, targetId) => {
    let newContent = content;

    if (highlightInStorage) {
      const dataArray = highlightInStorage.filter(data => data.source === "scenes" && data.sceneId === targetId);

      if (dataArray) {
        dataArray.forEach(data => {
          newContent = newContent.replace(data.text, `<span class="highlight">${data.text}</span>`)
        });
      }
    }

    return newContent
  };

  const renderList = () => {
    const hasReadSceneIdsInStorage = window.localStorage.getItem("hasReadSceneIds");
    const hasReadSceneIdsArray = hasReadSceneIdsInStorage ? JSON.parse(hasReadSceneIdsInStorage) : [];

    if (isAboveSm) {
      return (
        <Flex minW="fit-content" pos='relative'>
          <VStack 
            h="fit-content" 
            w="max-content" 
            pos="sticky" 
            top="100px" 
            mx={{ base: 2, md: 4 }}
            mt={32} 
            borderWidth="1px" 
            borderRadius={4} 
            shadow="md"
          >
            <Box px={3} py={2} borderBottom="1px solid">
              <Text>劇幕</Text>
            </Box>
            {sortedSceneIdList.length > 0 && sortedSceneIdList.map(id => {
              const hasRead = hasReadSceneIdsArray.indexOf(id) > -1;
              return (
                <Box px={3} py={2} onClick={() => onSelectScene(id)} key={`scene-${id}`}>
                  <Text color={id === selectedScene ? "#1A94DA" : ""} className={hasRead ? "" : "scene--unread"} >
                    {sceneMap[id] && sceneMap[id].title ? sceneMap[id].title : ""}
                  </Text>
                </Box>
              )
            })}
          </VStack>
        </Flex>
      )
    }

    return (
      <Box pos="fixed" left="1em" top={14} bg={bgColor} boxShadow="md" borderRadius={6}>
        <Menu
          autoSelect={false}
          colorScheme="twitter"
        >
          <MenuButton
            as={IconButton}
            aria-label="scenes"
            icon={<RiFileListLine />}
            variant="ghost"
            borderWidth="1px"
          />
          <MenuList>
            {sortedSceneIdList.length > 0 && sortedSceneIdList.map(id => {
              const hasRead = hasReadSceneIdsArray.indexOf(id) > -1;
  
              return (
                <MenuItem onClick={() => onSelectScene(id)} key={`scene-${id}`}>
                  <Text w="100%" align="center" color={id === selectedScene ? "#1A94DA" : ""} className={hasRead ? "" : "scene--unread"} >
                  {sceneMap[id] && sceneMap[id].title ? sceneMap[id].title : ""}
                  </Text>
                </MenuItem>
              )
            })}
          </MenuList>
        </Menu>
      </Box>

    )
  };

  const renderContent = () => {
    if (!selectedScene) {
      return <div className={`room-body-script`}><h2>請點選劇目上的章節</h2></div>
    }

    const targetScene = sceneMap[selectedScene];

    if (!targetScene) {
      return <div className={`room-body-script`}><h2>劇目資料下載中，請稍候 ...</h2></div>
    } else {
      if (targetScene.type === "VOTE") {
        if (selectedScene !== currentSceneId) {
          return <div className={`room-body-script`}><h2>投票已結束</h2></div>
        } else {
          if (voteDataList.length > 0) {
            return (
              <Votes 
                voteDataList={voteDataList}
                onOptionSelect={onOptionSelect}
                isReady={isReady}
                answerMap={answerMap}
                setAnswerMap={setAnswerMap}
              />
            )
          }
        }
      } else {
        const content = targetScene.content;
  
        if (!content) {
          // SEARCH 應該沒有 content
          return <div className={`room-body-script`}><h2>請依照主持人指示進行</h2></div>
        } else {
          const newContent = getContentWithKeyword(getHighlightContent(content, selectedScene), findKeyword);

          return (
            <div 
              className={`room-body-script`} 
              dangerouslySetInnerHTML={createMarkup(newContent)} 
              onMouseUp={handleTextSelect}
            />
          )
        }
      };
    }
  }

  const renderReadyButton = () => {
    const targetScene = sceneMap[selectedScene];

    if (!targetScene || targetScene.type === "SEARCH" || targetScene.id === "INTRO" || targetScene.id === "ENDING") return;

    return (
      <Box pos="fixed" right={{ base: "1em", sm: "30px" }} bottom={{ base: "3em", sm: "30px" }} bg={bgColor}>
        <Button 
          size={isAboveMd ? "lg" : "md"}
          leftIcon={isReady ? <FaRegLightbulb /> :  <FaLightbulb />} 
          colorScheme="green"
          variant={isReady ? "solid" : "outline"} 
          onClick={onReady}
        >
          {isReady ? "等待中" : "我好了"}
        </Button>
      </Box>
    )
  };

  const renderHighlighBox = () => {

    return (
      <Button
        pos="fixed"
        left={popoverPostion[0] ? popoverPostion[0] : "1em" }
        top={popoverPostion[1] ? popoverPostion[1] : "unset" }
        bottom={{ base: "3em", sm: "unset" }}
        px={2}
        py={1}
        borderRadius="4px"
        transform="translateY(-150%)"
        variant="solid"
        colorScheme="yellow"
        leftIcon={<RiMarkPenFill />}
        onClick={handleHighlightSave}
      >
        畫重點
      </Button>
    )
  };

  return (
    <Flex w="100%" h="100%" py={2} pos="relative">
      {findKeyword && (
        <Box pos="fixed" left={{ base: "5em", sm: "6.5em", md: "7.5em" }} top={14} zIndex="20" w="200px" shadow="md">
          <KeywordInput findKeyword={findKeyword} setFindKeyword={setFindKeyword} />
        </Box>
      )}
      {renderList()}
      {renderContent()}
      {renderReadyButton()}
      {isShowPopover && renderHighlighBox()}
    </Flex>
  )
};

export default Scenes