import React, { useState, useMemo } from "react";
import { Stack, HStack, VStack, Center, Box, Avatar, Text, Heading, Image } from "@chakra-ui/react";
import { generateMap, createMarkup } from '../../utils';

const Characters = props => {
  const { isBasicInfoReady, staticData, characterId, isAboveSm } = props;
  const { charactersList } = staticData;

  const [selected, setSelected] = useState('');

  const characterMap = useMemo(() => {
    if (!isBasicInfoReady || !charactersList) {
      return {};
    } else {
      return generateMap(charactersList)
    }
  }, [staticData]);

  return (
    <Stack w="100%" py={2}>
      <Center mb={5}>
        <VStack 
          w={{ base: "90%", sm: "300px", md: "450px" }} 
          p={{ base: 2, sm: 4, md: 5 }} 
          spacing={3} 
          shadow="md" 
          borderWidth="1px" 
          borderRadius={4} 
        >
          <Image
            borderRadius="full"
            boxSize={{ base: "250px", sm: "270px", md: "360px" }}
            bg="gray.100"
            objectFit="cover"
            src={selected && characterMap[selected] ? characterMap[selected].mediaSrc : ""}
            alt={selected && characterMap[selected] ? characterMap[selected].name : ""}
          />
          <Heading as="h3" size={isAboveSm ? "lg" : "md"}>
            {selected ? characterMap[selected].name : "請從下方選擇一個角色"}
          </Heading>
          <Text dangerouslySetInnerHTML={selected && characterMap[selected] ? createMarkup(characterMap[selected].intro) : null} />
        </VStack>
      </Center>
      <HStack spacing={1} justify={{ base: "center", sm: "flex-start" }} align="top" wrap="wrap" >
        {charactersList && charactersList.map(ele => {
          const { name, mediaSrc, id } = ele;

          return (
            <Box 
              w={{ base: "96px", sm: "128px" }}
              p={1} 
              borderWidth="2px"
              borderColor={id === selected ? "#1A94DA" : "transparent"}
              textAlign="center"
              onClick={() => setSelected(id)} 
              key={`ch-${id}`} 
            >
              <Avatar mb={2} size={isAboveSm ? "lg" : "md"} name={name} src={mediaSrc} />
              <Text isTruncated>{name}</Text>
              {characterId === id && <Text>（你）</Text>}
            </Box>
          )
        })}
      </HStack>
    </Stack>
  )
}

export default Characters;