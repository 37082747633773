export const FORBIDDEN_TIME = 15 * 1000;
export const TOAST_TIME = 1.7 * 1000;
export const TOAST_TIME_MOBILE = 0.8 * 1000;

export const sceneNaming = {
  INTRO: "INTRO",
  VOTE: "VOTE",
  ENDING: "ENDING"
};

export const allLocalStorageKeys = [
  "characterId", 
  "hasReadSceneIds", 
  "hasReadClueIds", 
  "hasDiggedClueIds",
  "hasUsedClueIds",
  "collection",
  "highlight",
  "note",
  "hasSampleClueExplored",
];

export const sampleClueMap = {
  "888": {
    category: "練習用線索",
    content: "恭喜！您已經成功找到一張線索卡！您的程式將會自動更新你的線索夾",
    shorthand: "一盆盆栽",
    title: "一盆盆栽",
    id: "888",
    isDeliverForbidden: true
  }
};
export const sampleClueIdList = ["888"];
export const sampleCategoryList = ["練習用線索"];