import React, { useRef, useEffect } from 'react';

let playerTimer;
const PLAYER_DELAY = 3 * 1000;

const AudioPlayer = props => {
  const { 
    src = '', 
    loop = false, 
    volume = 0,
  } = props;
  const playerRef = useRef(null);

  useEffect(() => {
    if (src && playerRef) {
      if (playerTimer) {
        clearTimeout(playerTimer)
      }

      playerTimer = setTimeout(() => {
        playerRef.current.play()
          .then(res => {
            playerRef.current.muted = false;
          })
          .catch(err => { console.log(err) });
      }, PLAYER_DELAY);
    }
  }, [src]);

  useEffect(() => {
    if (volume >= 0 && playerRef) {
      playerRef.current.volume = volume;
      playerRef.current.play();
    }
  }, [volume]);

  const handlePauseEvent = () => {
    if (playerRef) {
      playerRef.current.play();
    }
  };

  return (
    <div style={{ position: "fixed", left: "-1000%", bottom: 0 }}>
      <audio 
        src={src}
        ref={playerRef}
        loop={loop}
        volume={volume}
        controls
        muted
        onPause={handlePauseEvent}
      />
    </div>
  )
};

export default AudioPlayer;