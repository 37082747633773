import React, { useState } from "react";
import { 
  Button,
  Radio, 
  RadioGroup,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, } from "@chakra-ui/react";
import { BiSearchAlt } from 'react-icons/bi';

const TargetSelectDialog = props => {
  const { staticData, characterId, onClose, onSubmit } = props;
  const { charactersList } = staticData;

  const [selected, setSelected] = useState('');

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>選擇分享對象</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RadioGroup onChange={setSelected} value={selected}>
            <Stack spacing={2}>
              {charactersList.filter(ele => ele.id !== characterId).map((ele) => {
                return <Radio value={ele.id} colorScheme="green" key={ele.name}>{ele.name}</Radio>
              })}
              <Radio value="public" colorScheme="green">公開</Radio>
            </Stack>
          </RadioGroup>

        </ModalBody>
        <ModalFooter>
          <Button 
            rightIcon={<BiSearchAlt />}  
            colorScheme="green" 
            borderRadius={20}
            variant="solid" 
            onClick={() => onSubmit(selected)}
            disabled={!selected}
          >
            分享
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TargetSelectDialog;

