import React from "react";
import { Center, Spinner } from "@chakra-ui/react";
import style from '../style.scss';

const LoadingSpinner = props => {
  const { spinnerColor = "red.400", isLogo = false } = props;

  return (
    <Center 
      pos="fixed" 
      top="0" 
      left="0"
      w="100vw" 
      h="100vh" 
      zIndex="2000" 
      bg="black" 
      opacity={isLogo ? "1" : "0.5"}
      pointerEvents="none"
    >
      {isLogo ? (
        <>
          <div className="spinner-pulser" />
          <div className="spinner-pulser" />
          <div className="spinner-pulser" />
          <div className="spinner-brand-logo" style={{ backgroundImage: `url('/menu-logo.png')` }} />
        </>
      ) : (
        <Spinner color={spinnerColor} thickness="4px" size="xl" />
      )}
    </Center>
  )
};

export default LoadingSpinner;