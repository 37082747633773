import React, { useEffect } from "react";
import { 
  Stack,
  Heading,
  ListItem, 
  UnorderedList, 
  Text, 
} from "@chakra-ui/react";
import dayjs from 'dayjs';

const FORMAT = "HH:mm'ss"

const ActionLogs = props => {
  const { hasStarted, data, handleToastClose } = props;

  useEffect(() => {
    handleToastClose();
  }, []);

  return (
    <Stack p={4}>
      <Stack>
        <Heading size="lg">玩家行動紀錄</Heading>
        <Text>此處按時間由近到遠列出玩家在遊戲中的部分重要行動，目前紀錄關於「分享線索」、「使用線索」的資訊。</Text>
        {!hasStarted && <Text color="red.400" fontSize="2em">遊戲尚未開始</Text>}
        <UnorderedList p={4} spacing={2}>
          {data && Object.keys(data).reverse().map((timestamp, idx) => {
            const isUse = data[timestamp].includes("使用了");
            return (
              <ListItem key={`${timestamp}-${idx}`}>
                <Text color={isUse ? "red.400" : ""}>[{dayjs(parseInt(timestamp)).format(FORMAT)}] {data[timestamp]}</Text>
              </ListItem>
            )
          })}
        </UnorderedList>
      </Stack>
    </Stack>
  )
};

export default ActionLogs;