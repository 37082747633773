import React, { useMemo, useState, useRef, useEffect } from "react";
import {
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td, 
  Stack,
  Button,
  Image,
  IconButton,
  Radio, 
  RadioGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  UnorderedList,
  ListItem,
  VStack,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuOptionGroup,
  MenuDivider,
  HStack,
  Link
} from "@chakra-ui/react";
import { IoIosSend } from 'react-icons/io';
import { FiFilter } from 'react-icons/fi';
import { createMarkup } from '../../utils';
import { setActionPlayer } from '../../firebase';
import { STORAGE_BASE } from '../../api';

const NO_ONE = "none";

const Clues = props => {
  const { isBasicInfoReady, staticData, scenarioId, hasStarted, characterPlayerMap, playersStatus, onAssign } = props;
  const { clueIdList, clueMap, characterMap, charactersList, sceneMap } = staticData;
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState("");
  const [selectedClueId, setSelectedClueId] = useState("");
  const [filterCategories, setFilterCategories] = useState([]);
  const [filterHolders, setFilterHolders] = useState([]);
  const [selectedCharacterId, setSelectedCharacterId] = useState("");

  // TODO: radio -> checkbox ，增加複數邏輯，公開為全部選取

  useEffect(() => {
    if (isBasicInfoReady && clueIdList && clueMap) {
      clueIdList.sort((a, b) => {
        return clueMap[a].category.localeCompare(clueMap[b].category, 'zh-CN')
      });
    }
  }, [isBasicInfoReady, clueIdList, clueMap]);

  const categoryNames = useMemo(() => {
    if (isBasicInfoReady && clueIdList && clueMap) {
      let names = [];
      clueIdList.forEach(clueId => {
        const category = clueMap[clueId].category;
        if (names.indexOf(category) === -1) {
          names.push(category);
        }
      });
      setFilterCategories(names);
      return names;
    } else {
      return null;
    }
  }, [isBasicInfoReady, clueIdList, clueMap])

  const characterNames = useMemo(() => {
    if (isBasicInfoReady && charactersList) {
      let names = [];
      charactersList.forEach(ele => {
        names.push(ele.name)
      });
      setFilterHolders([...names, NO_ONE]);
      return names;
    } else {
      return null;
    }
  }, [isBasicInfoReady, charactersList]);

  const existCharacters = useMemo(() => {
    if (characterPlayerMap) {
      return Object.keys(characterPlayerMap).filter(chId => !!characterPlayerMap[chId]);
    }
  }, [characterPlayerMap]);

  const organizedClueMap = useMemo(() => {
    if (isBasicInfoReady && clueIdList && clueMap && characterMap) {
      const playerIdList = Object.keys(playersStatus);

      return clueIdList.reduce((accu, clueId) => {
        const clueData = clueMap[clueId];

        let holders = [];
        let isPublic = false;
        
        if (playersStatus) {
          holders = playerIdList
            .filter(playerId => {
              const { clues } = playersStatus[playerId]
              if (!!clues && ([clueId] in clues)) return true;
            })
            .map(playerId => {
            const { characterId, clues } = playersStatus[playerId]
            if (clues[clueId] === "PUBLIC") isPublic = true;
            return characterMap[characterId].name
          });
        }

        if (holders.length === 0) {
          holders.push(NO_ONE)
        }

        return {
          ...accu,
          [clueId]: {
            ...clueData,
            holders,
            isPublic
          }
        }
      }, {});
    }
  }, [isBasicInfoReady, clueIdList, clueMap, characterMap, playersStatus]);

  const handlePreviewOpen = (clueId) => {
    setIsPreviewOpen(true);
    setSelectedClueId(clueId);
  };

  const handlePreviewClose = () => {
    setSelectedClueId('');
    setIsPreviewOpen(false);
  };

  const handleClueAssign = () => {
    if (selectedTarget === "public") {
      onAssign(selectedClueId, "PUBLIC")
    } else {
      onAssign(selectedClueId, "PRIVATE", selectedTarget)
    }
    handleSelectorClose()
  };

  const handleSelectorOpen = (clueId) => {
    setIsSelectorOpen(true);
    setSelectedClueId(clueId);
  };

  const handleSelectorClose = () => {
    setSelectedClueId('');
    setSelectedTarget('');
    setIsSelectorOpen(false);
  };

  const handleTargetSelect = (target) => {
    setSelectedTarget(target);
  };

  const handleFilter = (column) => (name) => {
    if (column === "category") {
      setFilterCategories(name)
    } else if (column === "holder") {
      setFilterHolders(name)
    }
  };

  const handleFilterSelectAll = (column) => {
    if (column === "category") {
      setFilterCategories(categoryNames)
    } else if (column === "holder") {
      setFilterHolders([...characterNames, NO_ONE])
    }
  };

  const handleFilterUnSelectAll = (column) => {
    if (column === "category") {
      setFilterCategories([])
    } else if (column === "holder") {
      setFilterHolders([])
    }
  };

  const handleSearchCharacterSelect = (characterId) => {
    setSelectedCharacterId(characterId);

    if (characterId) {
      setActionPlayer(characterPlayerMap[characterId])
    } else {
      setActionPlayer("")
    }
  };

  const checkIsVisible = (category, holders) => {
    let isVisible = false;

    if ((filterCategories.indexOf(category) > -1) && 
      (filterHolders.some(name => {
        return holders.indexOf(name) > -1
      }))
    ) {
      isVisible = true;
    } 
    
    return isVisible;
  };

  const renderSelector = () => {
    if (!selectedClueId) return;

    return (
      <Modal isOpen={isSelectorOpen} onClose={handleSelectorClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>選擇指派「{clueMap[selectedClueId].title}」的對象</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup onChange={handleTargetSelect} value={selectedTarget}>
              <Stack spacing={2}>
                {charactersList
                  .filter(ele => existCharacters.indexOf(ele.id) > -1)
                  .map((ele) => {
                    return <Radio value={ele.id} colorScheme="red" key={ele.name}>{ele.name}</Radio>
                  })
                }
                <Radio value="public" colorScheme="red">公開</Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button 
              rightIcon={<IoIosSend />}  
              colorScheme="red" 
              borderRadius={20}
              variant="solid" 
              onClick={handleClueAssign}
              disabled={!selectedTarget}
            >
              指派
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  const renderFilter = (column) => {
    let title, value, names;

    switch (column) {
      case 'category':
        title = '類別';
        value = filterCategories;
        names = categoryNames;
        break;
      case 'holder':
        title = '持有人';
        value = filterHolders;
        names = characterNames;
        break;
    }

    return (
      <Box>
        <Menu closeOnSelect={false} strategy="fixed">
          <MenuButton
            as={IconButton}
            size="xs"
            aria-label="Options"
            icon={<FiFilter />}
            variant={names && value.length !== names.length ? "solid" : "ghost"}
          />
          <MenuList>
            <MenuItem onClick={() => handleFilterSelectAll(column)}>全選</MenuItem>
            <MenuItem onClick={() => handleFilterUnSelectAll(column)}>全不選</MenuItem>
            <MenuDivider />
            <MenuOptionGroup 
              title={title} 
              type="checkbox" 
              onChange={handleFilter(column)}
              defaultValue={names}
              value={value}
            >
              {Array.isArray(names) && names.map((name, idx) => {
                return <MenuItemOption value={name} key={`${name}-${idx}`}>{name}</MenuItemOption>
              })}
              {column === "holder" && <MenuItemOption value={NO_ONE}>無人持有</MenuItemOption>}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Box>
    )
  };

  return (
    <Stack p={5} spacing={5}>
      <HStack justify="space-between">
        <Heading size="xl">線索取得狀況</Heading>
      </HStack>
      {!hasStarted && (
        <Stack>
          <Text fontSize="xl" color="red.600">提醒：遊戲尚未開始</Text>
        </Stack>
      )}
      {characterMap && hasStarted && (
        <Stack>
          <Text fontSize="xl">指定蒐證角色</Text>
          <HStack>
            {existCharacters.map(chId => {
              const name = characterMap[chId] ? characterMap[chId].name : "";
              return (
                <Button
                  colorScheme="blue"
                  variant={selectedCharacterId === chId ? "solid" : "outline"}
                  disabled={selectedCharacterId === chId}
                  onClick={() => handleSearchCharacterSelect(chId)}
                  key={chId}
                >
                  {name}
                </Button>
              )
            })}
            <Button variant="outline" onClick={() => handleSearchCharacterSelect('')}>取消</Button>
          </HStack>
        </Stack>
      )}
      <Box minH="180vh">
        <Table variant="striped" >
          <Thead>
            <Tr>
              <Th p={2} textAlign="center">編號</Th>
              <Th p={2}>
                <HStack>
                  <Text>類別</Text>
                  {renderFilter("category")}
                </HStack>
              </Th>
              <Th p={2}>名稱（點擊看線索細節）</Th>
              <Th p={2} textAlign="center">可探索</Th>
              <Th p={2} textAlign="center">可分享</Th>
              <Th p={2} textAlign="center">限量</Th>
              <Th w="100px" p={2}>子線索</Th>
              <Th p={2} textAlign="center">公開</Th>
              <Th p={2}>
                <HStack>
                  <Text>持有人</Text>
                  {renderFilter("holder")}
                </HStack>
              </Th>
              <Th p={2}>指派</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(isBasicInfoReady && clueIdList) && clueIdList.map((clueId, idx) => {
              const { id, category, title, childClueId, code, isExploreForbidden, availableFromIndex, isDeliverForbidden, limit, isPublic, holders } = organizedClueMap[clueId];

              if (!checkIsVisible(category, holders)) return null;

              const hasNoneHolder = holders.indexOf(NO_ONE) > -1;

              return (
                <Tr key={`${id}-${idx}`}>
                  <Td p={2}>{id}</Td>
                  <Td p={2}>{category}</Td>
                  <Td p={2}><Link onClick={() => handlePreviewOpen(id)}>{title}</Link></Td>
                  <Td p={2} textAlign="center">{isExploreForbidden ? "X" : availableFromIndex && sceneMap[availableFromIndex] ? sceneMap[availableFromIndex].title : "O"}</Td>
                  <Td p={2} textAlign="center">{isDeliverForbidden ? "X" : "O"}</Td>
                  <Td p={2} textAlign="center">{limit > 0 ? limit : ""}</Td>
                  <Td p={2}>
                    {childClueId 
                      ? code 
                        ? `可解碼，子線索 ${childClueId}，密碼：「${code}」` 
                        : `可深入，子線索 ${childClueId}` 
                      : "無"
                    }
                  </Td>
                  <Td p={2} textAlign="center">
                    {isPublic ? "O" : "X"}
                  </Td>
                  <Td p={2}>
                    {isPublic 
                      ? <Text>所有人</Text>
                      : hasNoneHolder 
                        ? <Text>無人</Text>
                        : (<UnorderedList>
                            {holders.map(name => {
                              return <ListItem key={`holder-${name}`}>{name}</ListItem>
                            })}
                          </UnorderedList>)
                    }
                  </Td>
                  <Td p={2}>
                    <IconButton 
                      colorScheme="red"
                      size="sm"
                      variant="outline"
                      icon={<IoIosSend/>}
                      onClick={() => handleSelectorOpen(id)}
                    />
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </Box>
      {isSelectorOpen && renderSelector()}
      {isPreviewOpen && (
        <CluePreview 
          isOpen={isPreviewOpen}
          clueData={clueMap[selectedClueId]} 
          scenarioId={scenarioId} 
          onClose={handlePreviewClose}
        />
      )}
    </Stack>
  )
}

const CluePreview = props => {
  const { isOpen, clueData, scenarioId, onClose } = props;
  const { id, title, fileType, ytId, content } = clueData;
  const [mediaSrc, setMediaSrc] = useState("");
  const ytRef = useRef(null);

  useEffect(() => {
    if (clueData && clueData.fileName) {
      setMediaSrc(`${STORAGE_BASE}/${scenarioId}/clues/${clueData.fileName}`)
    }
  }, [clueData])

  const isShowImage = (!fileType || fileType === "image") && mediaSrc !== "";
  const isShowAudio = fileType === "audio" && mediaSrc !== "";
  const isShowVideo = fileType === "video" && mediaSrc !== "";
  const isShowYoutube = fileType === "youtube" && ytId !== "";

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>線索細節：編號 {id}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            {(isShowImage || isShowAudio || isShowVideo || isShowYoutube) && (
              <Box w="100%">
                {isShowImage && mediaSrc && (
                  <Image
                    boxSize="100%"
                    fit="contain"
                    src={mediaSrc}
                    alt={title}
                  />
                )}
                {isShowAudio && <audio src={mediaSrc} controls style={{ margin: "auto "}} />}
                {isShowVideo && <video src={mediaSrc} controls />}
                {isShowYoutube && (
                  <iframe 
                    id="ytplayer" 
                    ref={ytRef}
                    type="text/html" 
                    width="500" 
                    height="281"
                    src={`https://www.youtube-nocookie.com/embed/${ytId}?rel=0&cc_load_policy=1&fs=1&color=white&iv_load_policy=3`}
                    frameBorder="0" 
                    allowFullScreen
                  />
                )}
              </Box>
            )}
            <Heading as="h3" size="lg" align="center">
              {title || "請選擇一個線索進行調查"}
            </Heading>
            {content && <p className={`room-clue-content`} dangerouslySetInnerHTML={createMarkup(content)} />}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default Clues;