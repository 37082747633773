import React, { useState, useEffect } from "react";
import { 
  Stack, 
  Text, 
  RadioGroup, 
  Radio, 
  Checkbox, 
  Divider,
} from "@chakra-ui/react";

const Votes = props => {
  const { voteDataList, onOptionSelect, isReady, answerMap, setAnswerMap } = props;
  const [numberList, setNumberList] = useState([]);
  const [voteTable, setVoteTable] = useState({});

  useEffect(() => {
    if (voteDataList.length > 0) {
      const newNumberList = voteDataList
        .sort((a, b) => a.number - b.number)
        .map(ele => ele.number.toString());

      const newVoteTable = voteDataList.reduce((accu, data) => {
        return {
          ...accu,
          [data.number.toString()]: {
            ...data,
          }
        }
      }, {});

      let newAnswerMap;

      if (Object.keys(answerMap).length) {
        newAnswerMap = answerMap;
      } else {
        newAnswerMap = newNumberList.reduce((accu, number) => {
          return {
            ...accu,
            [number]: []
          }
        }, {});
      }
      setNumberList(newNumberList);
      setVoteTable(newVoteTable);
      setAnswerMap(newAnswerMap);
    }
  }, [voteDataList]);

  const handleAnswerSelect = (number, answer) => {
    const currentAnswer = answerMap[number];
    const vote = voteTable[number];

    let newAnswer = [];

    if (vote.limit === 1) {
      // 單選題
      if (currentAnswer.includes(answer)) {
        // 已選過，不做事
        return;
      } else {
        // 未選過，答案改成這個，其他排除
        newAnswer = [answer];
      }
    } else {
      // 複選題
      if (currentAnswer.includes(answer)) {
        // 已選過，取消選取
        newAnswer = currentAnswer.filter(ele => ele !== answer);
      } else {
        // 未選過，判斷是否可再加入
        if (!vote.limit) {
          // 無限多選，放入
          newAnswer = [...currentAnswer, answer];
        } else {
          // 有限多選，判斷數量
          if (vote.limit <= currentAnswer.length) {
            // 已達上限，不做事
            return;
          } else {
            // 未達上限，放入
            newAnswer = [...currentAnswer, answer];
          }
        }
      }
    }
    
    const newAnswerMap = {
      ...answerMap,
      [number]: newAnswer
    }

    setAnswerMap(newAnswerMap);

    vote.options.forEach(option => {
      onOptionSelect({ number, option, value: newAnswer.indexOf(option) > -1 })
    });
  };

  return (
    <Stack w="100%" height="fit-content" p={5} spacing={10}>
      {numberList.map((number, idx) => {
        const { topic, options, limit } = voteTable[number];
        const answer = answerMap[number];

        return (
          <Stack key={`${topic}-${idx}`}>
            <Text fontSize="2xl">{idx + 1}. {topic}</Text>
            <Divider />
            {limit && <Text color="red.400">本題可選 {limit} 個答案</Text>}
            {limit === 1 ? (
              <RadioGroup>
                <Stack spacing={4} pl={4}>
                  {options.map(option => {
                    return (
                      <Radio 
                        colorScheme="red" 
                        key={`${number}-${option}`}
                        isChecked={answer.includes(option)}
                        isDisabled={isReady}
                        onChange={() => handleAnswerSelect(number, option)}
                      >
                        {option}
                      </Radio>
                    )
                  })}
                </Stack>
              </RadioGroup>
            ) : (
              <Stack spacing={4} pl={4}>
                {options.map(option => {
                  return (
                    <Checkbox 
                      colorScheme="red"
                      key={`${number}-${option}`}
                      isChecked={answer.includes(option)}
                      isDisabled={isReady}
                      onChange={() => handleAnswerSelect(number, option)}
                    >
                      {option}
                    </Checkbox>
                  )
                })}
              </Stack>
            )}
        </Stack>
        )
      })}
    </Stack>
  )
}

export default Votes;