import React, { useState } from "react";
import { 
  Flex,
  Stack,
  HStack, 
  Button, 
  Heading,
  Text, 
  Image,
  Input, 
  InputGroup, 
  InputRightElement, 
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Link,
  Icon
} from "@chakra-ui/react";
import { BiLinkExternal, BiCopyAlt, BiStopCircle, BiReset } from 'react-icons/bi';

const Info = props => {
  const { 
    roomId = '',
    roomName = '', 
    platform = '',
    platformUrl = '', 
    platformPw = '', 
    isBasicInfoReady = false, 
    staticData,
    handleToastShow,
    onGameClose,
    onGameClear,
    isLoading = false,
  } = props;
  const [confirmDialogType, setConfirmDialogType] = useState("");
  const [reason, setReason] = useState('');

  const { title, mainImgSrc } = staticData;

  const renderBasicInfo = () => {
    return (
      <Stack>
        <Heading as="h2" size="lg">本日上映：《{title}》</Heading>
        <Image 
          width="300px"
          fit="contain"
          src={mainImgSrc}
        />
      </Stack>
    )
  };

  const handleOpenExternal = () => {
    window.open(platformUrl);
  }

  const handleCopy = async id => {
    const element = document.getElementById(id);

    try {
      await navigator.clipboard.writeText(element.value);
      handleToastShow('COPY_SUCCESS');
    } catch (err) {
      console.debug(err);
    }
  };

  const handleCloseGameClick = () => {
    setConfirmDialogType("CLOSE");
  };

  const handleClearGameClick = () => {
    setConfirmDialogType("CLEAR");
  };

  const handleDialogCancel = () => {
    setConfirmDialogType("")
  };

  const handleInput = e => {
    setReason(e.target.value)
  };

  const handleCloseGameSubmit = () => {
    onGameClose(reason)
  };

  const handleClearRoomClick = () => {
    onGameClear();
  };

  const renderConfirmDialog = () => {

    const isClose = confirmDialogType === "CLOSE";

    return (
      <Modal size="lg" isOpen={true} onClose={handleDialogCancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>確定要【{isClose ? "結束" : "重來"}】這場遊戲嗎？</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={3} color="red.600">注意！按下確定後主持人以及所有玩家都無法繼續操作！</Text>
            {isClose ? (
              <>
                <Text mb={3}>若因遭遇特殊狀況必須終止遊戲，請填寫原因。若遊戲正常結束，可略過此欄位。</Text>
                <Input 
                  value={reason}
                  placeholder="請填寫原因" 
                  onChange={handleInput}
                />
              </>
            ) : (
              <Text mb={3}>清除資料後此房間可繼續使用，但本次使用不列入打卡紀錄。如果狀況特殊需要重開，請在打卡下班時說明原因。</Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button 
              rightIcon={isClose ? <BiStopCircle /> : <BiReset />}  
              colorScheme="red" 
              borderRadius={20}
              variant="solid" 
              onClick={isClose ? handleCloseGameSubmit : handleClearRoomClick}
              isLoading={isLoading}
            >
              {isClose ? "確定結束" : "確定重來"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )

  };

  return (
    <Stack spacing={4} p={4}>
      <Flex justify="space-between">
        <Heading as="h1" size="lg">遊戲房間：{roomName}</Heading>
        <HStack>
          <Button 
            colorScheme="orange"
            variant="solid"
            rightIcon={<BiReset/>}
            onClick={handleClearGameClick}
          >
            重新來過
          </Button>
          <Button 
            colorScheme="red"
            variant="solid"
            rightIcon={<BiStopCircle/>}
            onClick={handleCloseGameClick}
          >
            打卡下班
          </Button>
        </HStack>
      </Flex>
      <Stack>
        <Text>玩家進入網址</Text>
        <InputGroup onClick={() => handleCopy('room')}>
          <Input id="room" variant="filled" value={`${window.location.origin}/r/${roomId}`} isReadOnly />
          <InputRightElement children={<BiCopyAlt />}  />
        </InputGroup>
      </Stack>
      <Text>請搭配 {platform} 進行本次遊戲</Text>
      <HStack>
        <InputGroup onClick={() => handleCopy('platformUrl')}>
          <Input id="platformUrl" variant="filled" value={platformUrl} isReadOnly />
          <InputRightElement children={<BiCopyAlt />}  />
        </InputGroup>
        <Button 
          variant="solid" 
          colorScheme="twitter" 
          rightIcon={<BiLinkExternal />}
          onClick={() => handleOpenExternal(platformUrl)} 
        >
          開新分頁
        </Button>
      </HStack>
      {platformPw && (
        <Stack>
          <Text>Password: </Text>
          <InputGroup w="fit-content" onClick={() => handleCopy('platformPw')}>
            <Input id="platformPw" variant="filled" value={platformPw} isReadOnly />
            <InputRightElement children={<BiCopyAlt />}  />
          </InputGroup>
        </Stack>
      )}
      <Divider />
      {isBasicInfoReady && renderBasicInfo()}
      {confirmDialogType !== "" && renderConfirmDialog()}
    </Stack>
  )
};

export default Info;