export const generateIdList = dataArray => {
  return dataArray.map(data => data.id);
};

export const generateMap = dataArray => {
  return dataArray.reduce((accu, data) => {
    return {
      ...accu,
      [data.id]: data
    }
  }, {});
};

export const transformSnapshot = snapshot => {
  let map = {};
  snapshot.forEach((snap) => {
    map[snap.key] = snap.val();
  });

  return map;
}

export const createMarkup = htmlString => {
  return { __html: htmlString }
};

export const getSelectedText = () => {
  let text = "";
  if (window.getSelection) {
    text = window.getSelection();
  } else if (document.getSelection) {
    text = document.getSelection();
  } else if (document.selection) {
    text = document.selection.createRange().text;
  }

  return text.toString();
};

const FIND_LOWER_LIMIT = 2;

export const getContentWithKeyword = (content, keyword) => {
  let newContent = content;

  if (keyword.length >= FIND_LOWER_LIMIT) {
    newContent = newContent.replaceAll(keyword, `<span class="keyword">${keyword}</span>`)
  }

  return newContent
};

export const trackEvent = (action, params) => {
  if (window.gtag) {
    window.gtag('event', action, params);
  }
};