import React, { useMemo, useState, useEffect } from "react";
import {
  Flex,
  HStack,
  Stack, 
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td, 
  InputGroup,
  Input,
  InputRightElement,
  Button, 
  Text,
  Radio, 
  RadioGroup,
  UnorderedList,
  ListItem,
  Divider,
  Link,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { BsFillPeopleFill } from 'react-icons/bs';
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoIosSend } from 'react-icons/io';
import { BiDice6 } from 'react-icons/bi';
import { setChooseCharacterBatch, setNewExplorableCount } from '../../firebase';
import { transferPlayerData, getScene } from '../../api';
import { Scenes } from '../../Room/components';
import { generateIdList, generateMap } from '../../utils';
import { LoadingSpinner } from './';

// TODO: 踢除路人功能

const Players = props => {
  const { 
    roomId,
    isBasicInfoReady, 
    staticData, 
    scenarioId,
    playerNameMap, 
    characterPlayerMap, 
    playersStatus, 
    hasStarted, 
    isShowChoice, 
    onChoiceToggle,
    isShowNotice,
    noticeMessage,
    setNoticeMessage,
    onNoticeToggle,
    onSceneSet,
    isPosting,
    onToast
  } = props;
  const { characterIdList, characterMap, scenesOrder } = staticData;
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPreview, setIsShowPreview] = useState(false);
  const [previewSceneIdList, setPreviewSceneIdList] = useState([]);
  const [previewSceneMap, setPreviewSceneMap] = useState({});
  const [previewSelectedScene, setPreviewSelectedScene] = useState('');
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [targetPlayerId, setTargetPlayerId] = useState("");
  const [editingCount, setEditingCount] = useState(null);

  const hasAnyCharacterSelected = useMemo(() => {
    return Object.values(characterPlayerMap).filter(val => !!val).length > 0;
  }, [characterPlayerMap]);

  const handleIsShowChoice = () => {
    onChoiceToggle(isShowChoice ? "OFF" : "ON");
  };

  const handleNoticeInput = e => {
    setNoticeMessage(e.target.value);
  };

  const handleNoticeInputClear = () => {
    setNoticeMessage("");
  };

  const handleScriptPreview = async (characterId) => {
    setIsLoading(true);

    const lastSceneId = scenesOrder[scenesOrder.length - 1]

    const params = {
      scenarioId, 
      sceneId: lastSceneId,
      count: 0,
      characterId,
    }

    if (lastSceneId === "ENDING") {
      params.options = { branchKey: "ALL" }
    }

    try {
      const response = await getScene(params)

      setPreviewSceneIdList(generateIdList(response.data))
      setPreviewSceneMap(generateMap(response.data))
      setIsShowPreview(true);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTransferDataClick = characterId => {
    setDialogType("TRANSFER_PLAYER_DATA")
    setIsShowConfirmDialog(true);

    setTargetPlayerId(characterPlayerMap[characterId]);
  };

  const handleEditExplorableCountClick = (characterId, currentCount) => {
    setDialogType("EDIT_EXPLORABLE_COUNT")
    setIsShowConfirmDialog(true);

    setTargetPlayerId(characterPlayerMap[characterId]);
    setEditingCount(currentCount);
  };

  const handlePreviewClose = () => {
    setIsShowPreview(false);
  };

  const handleEnterFirstSceneClick = () => {
    setDialogType("START_THE_GAME")
    setIsShowConfirmDialog(true);
  };
  
  const handleChoiceFinish = () => {
    onSceneSet(scenesOrder[1]);
    handleConfirmDialogClose();
  }

  const handleCharacterRandom = () => {
    let playerIdList = Object.keys(playerNameMap);

    let randomResult = {}

    characterIdList.forEach((characterId) => {
      if (playerIdList.length > 0) {
        const randomIndex = Math.floor(Math.random() * playerIdList.length);
        const playerId = playerIdList[randomIndex];
  
        playerIdList.splice(randomIndex, 1);
        randomResult[characterId] = playerId;
      } else {
        randomResult[characterId] = "";
      }
    });

    setChooseCharacterBatch(randomResult)
  };

  const handleTransferPlayerDataConfirm = newPlayerId => {
    setIsLoading(true);
    transferPlayerData({
      roomId, 
      playerId: targetPlayerId, 
      newPlayerId
    }).then(res => {
        if (res.data.isSuccess) {
          onToast("TRANSFER_SUCCESS");
          setIsLoading(false);
          handleConfirmDialogClose();
        }
      })
      .catch(err => {
        console.log(err)
        onToast("FAILED");
        setIsLoading(false);
      });
  };

  const handleEditExplorableCountConfirm = (newCount) => {
    setIsLoading(true);
    setNewExplorableCount(targetPlayerId, parseInt(newCount))
      .then(res => {
        if (res.isSuccess) {
          setIsLoading(false);
          handleConfirmDialogClose();
        }
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false);
      });
  };

  const handleConfirmDialogClose = () => {
    setIsShowConfirmDialog(false)
    setDialogType("");
    setTargetPlayerId("");
    setEditingCount(null);
  };

  return (
    <Stack pos="relative" h="100%" p={5} spacing={5}>
      {isLoading && <LoadingSpinner />}
      {isShowPreview ? (
        <Box pos="absolute" w="100%" h="100%" zIndex="20">
          <Scenes 
            isAboveSm={true}
            sceneIdList={previewSceneIdList}
            sceneMap={previewSceneMap}
            selectedScene={previewSelectedScene}
            onSelectScene={setPreviewSelectedScene}  
            onReady={handlePreviewClose}
          />
        </Box>
      ) : (
        <Flex w="100%" h="100%" flexDirection="column" justify="space-between">
          <Stack>
            <Heading size="xl">玩家與角色資料</Heading>
            <Text color="green.400">點角色名稱可以查看該角色劇本內容（目前還無法看投票），按「我好了」可以回此畫面。</Text>
            <Table variant="striped" >
              <Thead>
                <Tr>
                  <Th>角色名稱</Th>
                  <Th>玩家名稱</Th>
                  <Th>剩餘探索次數</Th>
                </Tr>
              </Thead>
              <Tbody>
                {isBasicInfoReady && characterIdList && characterIdList.map(id => {
                  const { name } = characterMap[id];
                  const playerId = characterPlayerMap[id];
                  const playerName = playerNameMap[playerId] || playerId;
                  const explorableCount = playersStatus && playersStatus[playerId] 
                    && 'explorableCount' in playersStatus[playerId]
                    ? playersStatus[playerId].explorableCount : "N/A";

                  return (
                    <Tr key={name}>
                      <Td>
                        <Link onClick={() => handleScriptPreview(id) }>{name}</Link>
                      </Td>
                      <Td>
                        <Link onClick={() => handleTransferDataClick(id)}>{playerName}</Link>
                      </Td>
                      <Td>
                        {explorableCount === "N/A" 
                          ? "N/A"
                          : <Link onClick={() => handleEditExplorableCountClick(id, explorableCount)}>{explorableCount}</Link>
                        }
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
            <Divider />
            <HStack justify="center">
              <Button 
                colorScheme="red"
                variant={isShowChoice ? "solid" : "outline"}
                rightIcon={<BsFillPeopleFill />}
                onClick={handleIsShowChoice}
                disabled={hasStarted}
              >
                {hasStarted 
                  ? "遊戲已開始" 
                  : isShowChoice 
                    ? "選角中..." 
                    : "開始選角"
                  }
              </Button>
              {!hasStarted && !isShowChoice && (
                <Button 
                  colorScheme="red"
                  variant="outline"
                  rightIcon={<BiDice6 />}
                  onClick={handleCharacterRandom}
                >
                  隨機分配角色
                </Button>
              )}
              {!hasStarted && (
                <Button 
                  colorScheme="red"
                  variant="outline"
                  onClick={handleEnterFirstSceneClick}
                  disabled={!hasAnyCharacterSelected}
                >
                  進入第一環節
                </Button>
              )}
            </HStack>
            <Stack>
              <Text>在此房間中的玩家：</Text>
              <UnorderedList px={5}>
                {Object.values(playerNameMap).map(name => 
                  <ListItem key={name}>{name}</ListItem>
                )}
              </UnorderedList>
            </Stack>
          </Stack>
          <Stack>
            <Heading>發出全體蓋板公告</Heading>
            <Text color="red.500">注意：開啟後所有玩家暫時無法操作 LARP Bar，請謹慎使用，並且在使用完後按「關閉」。</Text>
            <HStack>
              <InputGroup>
                <Input variant="flushed" value={noticeMessage} onChange={handleNoticeInput} placeholder="請輸入廣播內容" />
                {noticeMessage.length > 0 && <InputRightElement children={<AiFillCloseCircle />} onClick={handleNoticeInputClear} />}
              </InputGroup>
              <Button 
                variant={isShowNotice ? "solid" : "outline"}
                colorScheme="red" 
                rightIcon={isShowNotice ? null : <IoIosSend />}
                onClick={onNoticeToggle} 
                disabled={!isShowNotice && noticeMessage.length === 0}
              >
                {isShowNotice ? "關閉" : "開啟"}
              </Button>
            </HStack>
          </Stack>
        </Flex>
      )}
      {isShowConfirmDialog && (
        <Dialog
          type={dialogType}
          currentValue={editingCount}
          playerNameMap={playerNameMap}
          characterPlayerMap={characterPlayerMap}
          isPosting={isPosting}
          onClose={handleConfirmDialogClose}
          onChoiceFinish={handleChoiceFinish}
          onTranfserConfirm={handleTransferPlayerDataConfirm}
          onEditConfirm={handleEditExplorableCountConfirm}
        />
      )}
    </Stack>
  )
}

const Dialog = props => {
  const { 
    type, 
    currentValue,
    playerNameMap,
    characterPlayerMap,
    isPosting, 
    onClose, 
    onChoiceFinish, 
    onTranfserConfirm, 
    onEditConfirm, 
  } = props;

  const [value, setValue] = useState(0);
  const [targetId, setTargetId] = useState("");

  useEffect(() => {
    if (currentValue > -1) {
      setValue(currentValue)
    }
  }, [currentValue])

  const genBody = () => {
    if (type) {
      switch (type) {
        case "START_THE_GAME":
          return (
            <Text fontSize="xl">確定要以此角色配置開始遊戲嗎？</Text>
          );
        case "TRANSFER_PLAYER_DATA":
          const outsidePlayerIdList = Object.keys(playerNameMap).filter(playerId => {
            return Object.values(characterPlayerMap).indexOf(playerId) === -1
          });

          return (
            <Stack>
              <Text mb={2}>說明：當遊戲進行到途中，玩家因為更換瀏覽器或者裝置等原因無法繼續使用原本的瀏覽器回到遊戲中，會被要求重新加入遊戲，此時請玩家再輸入一次新名字，主持人可使用此功能轉移角色資料，讓玩家能繼續進行遊戲。</Text>
              <Text mb={4}>注意：只能轉移到目前沒有扮演角色的玩家身上。</Text>
              <RadioGroup onChange={handleTargetSelect} value={targetId}>
                <Stack spacing={2}>
                  {outsidePlayerIdList.map(playerId => {
                      return <Radio value={playerId} colorScheme="red" key={playerId}>{playerNameMap[playerId]}</Radio>
                    })
                  }
                </Stack>
              </RadioGroup>
            </Stack>
          );
        case "EDIT_EXPLORABLE_COUNT":
          return (
            <HStack>
              <Text>請輸入新的探索次數：</Text>
              <NumberInput 
                maxW={24} 
                inputMode="numeric"
                value={value}
                min={0} 
                max={99}
                onChange={handleCountChange}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </HStack>
          );
      }
    }
  };

  const header = useMemo(() => {
    switch (type) {
      case "START_THE_GAME":
        return "確認進入下一幕";
      case "TRANSFER_PLAYER_DATA":
        return "轉移角色資料";
      case "EDIT_EXPLORABLE_COUNT":
        return "修改探索次數";
      default:
        return ""
    }
  }, [type]);

  const onConfirm = () => {
    if (type) {
      switch (type) {
        case "START_THE_GAME":
          onChoiceFinish();
          break;
        case "TRANSFER_PLAYER_DATA":
          onTranfserConfirm(targetId);
          break;
        case "EDIT_EXPLORABLE_COUNT":
          onEditConfirm(value);
          break;
      }
    }
  };

  const handleCountChange = value => {
    setValue(value)
  };

  const handleTargetSelect = target => {
    setTargetId(target);
  };

  return (
    <Modal 
      isOpen={true} 
      isCentered 
      motionPreset="scale"
      onClose={onClose}
      closeOnEsc={true}
      closeOnOverlayClick={true} 
      size={type === "TRANSFER_PLAYER_DATA" ? "lg" : "md"}
    >
      <ModalOverlay />
      <ModalCloseButton />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>
          {genBody()}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose} disabled={isPosting}>
            取消
          </Button>
          <Button 
            variant="outline" 
            colorScheme="red"
            onClick={onConfirm} 
            isLoading={isPosting}
          >
            確定
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

export default Players;