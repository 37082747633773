import React, { useMemo } from "react";
import {
  Text,
  Heading,
  Flex,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  UnorderedList,
  ListItem,
  Divider,
} from "@chakra-ui/react";

const SearchClueStepsWindow = props => {
  const { clueSteps } = props;
  const { clues, steps } = clueSteps;

  const organizedSteps = useMemo(() => {
    if (clues && steps) {
      return steps.map(step => {
        const { clueIds, ...stepData } = step
    
        let categories = {};
    
        clueIds.forEach(clueId => {
          const { category } = clues[clueId];

          if (category in categories) {
            categories[category].push(clueId);
          } else {
            categories[category] = [clueId];
          }
        })
  
        return {
          ...stepData,
          categories,
        }
      });
    } else {
      return null
    }
  }, [clueSteps]);

  return (
    <Modal 
      isOpen={true} 
      isCentered 
      size="3xl"
      motionPreset="scale"
      onClose={() => {}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="3xl" fontWeight="bold" align="center">請告訴主持人你要蒐證的位置</Text>
        </ModalHeader>
        <ModalBody>
          <Flex flexDirection="column" justify="center">
            {organizedSteps && organizedSteps.map((step, idx) => {
              const { stepKey, categories, searchLimit } = step;

              return (
                <Stack key={`${stepKey}-${idx}`}>
                  <Heading align="center" size="md">{idx +1}. {stepKey} (可蒐證 {searchLimit} 張)</Heading>
                  <Flex justify="center">
                    {Object.keys(categories).map((categoryName, idx) => {
                      const clueIds = categories[categoryName];

                      return (
                        <Stack key={`${categoryName}-${idx}`} p={4} shadow="md">
                          <Text>位置：{categoryName}</Text>
                          <Divider />
                          <UnorderedList px={4}>
                            {clueIds.map((clueId, idx) => {
                              return <ListItem key={`${clueId}-${idx}`} pb={2}>{clues[clueId].shorthand}</ListItem>
                            })};
                          </UnorderedList>
                        </Stack>
                      )
                    })}
                  </Flex>

                </Stack>
              )
            })}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Text fontSize="lg">請靜待主持人指示下一步行動 ...</Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

export default SearchClueStepsWindow;