import React, { useMemo, useState, useEffect } from "react";
import {
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td, 
  Stack,
  HStack, 
  Button, 
  Divider,
  Select,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { FaHandshake } from 'react-icons/fa';

const ALL_BRANCHES = "ALL";

const Endings = props => {
  const { 
    isBasicInfoReady, 
    staticData, 
    characterPlayerMap, 
    playersStatus, 
    hasStarted, 
    endingBranches, 
    setEndingBranches,
    onSend
  } = props;
  const { characterIdList, characterMap } = staticData;
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);

  useEffect(() => {
    // 若只有一個結局，預設選取
    const defaultEndingBranches = characterIdList.reduce((accu, chId) => {
      const { branchesList } = characterMap[chId];
        
      return {
        ...accu,
        [chId]: branchesList.length === 1 && branchesList[0].key ? branchesList[0].key : "",
      }
    }, {});

    setEndingBranches(defaultEndingBranches);
  }, []);

  const hasAnyBranchSelected = useMemo(() => {
    return Object.values(endingBranches).some(value => value !== "");
  }, [endingBranches]);

  const organizedCharacterMap = useMemo(() => {
    if (!characterIdList) return;
    
    return characterIdList.reduce((accu, chId) => {
      const { name, branchesList } = characterMap[chId];
      const hasPlayer = chId in characterPlayerMap && characterPlayerMap[chId].length > 0;
      const selectedBranchKey = endingBranches[chId];
      const selectedBranchName = selectedBranchKey 
        ? selectedBranchKey === ALL_BRANCHES
          ? "結局全開"
          : branchesList.find(ele => ele.key === selectedBranchKey).title
        : "";

      return {
        ...accu,
        [chId]: {
          name,
          branchesList,
          hasPlayer,
          selectedBranchKey,
          selectedBranchName
        }
      }
    }, {})
  }, [characterIdList, endingBranches]);

  const handleBranchSelect = characterId => e => {
    setEndingBranches({
      ...endingBranches,
      [characterId]: e.target.value
    })
  };

  const handleAssignClick = () => {
    setIsShowConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setIsShowConfirmDialog(false);
  };

  const handleBranchSend = () => {
    onSend();
    handleConfirmDialogClose();
  };

  const renderConfirmDialog = () => {
    return (
      <Modal 
        isOpen={true} 
        isCentered 
        motionPreset="scale"
        onClose={handleConfirmDialogClose}
        closeOnEsc={true}
        closeOnOverlayClick={true} 
      >
        <ModalOverlay />
        <ModalCloseButton />
        <ModalContent>
          <ModalHeader>確認分配結局</ModalHeader>
          <ModalBody>
            <Text fontSize="xl">
              請確定每個角色分配結局如下：
            </Text>
            <UnorderedList>
              {characterIdList.map((id, idx) => {
                const { name, hasPlayer, selectedBranchName } = organizedCharacterMap[id];
                return <ListItem key={`${name}-${idx}`} p={1}>{name}：{selectedBranchName}</ListItem>
              })}
            </UnorderedList>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleConfirmDialogClose}>
              取消
            </Button>
            <Button 
              variant="outline" 
              colorScheme="red"
              onClick={handleBranchSend} 
            >
              確定
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  };

  return (
    <Stack h="100%" p={5} spacing={5}>
      <Heading size="xl">分配角色結局</Heading>
      {!hasStarted && (
        <Stack>
          <Text fontSize="xl" color="red.600">提醒：遊戲尚未開始</Text>
        </Stack>
      )}
      <Table variant="striped" >
        <Thead>
          <Tr>
            <Th>角色名稱</Th>
            <Th>結局分支</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isBasicInfoReady && characterIdList && characterIdList.map(id => {
            const { name, branchesList, hasPlayer, selectedBranchKey } = organizedCharacterMap[id];

            return (
              <Tr key={name}>
                <Td>{name}{hasPlayer ? "" : "（無人扮演）"}</Td>
                <Td>
                  <Select 
                    variant="flushed" 
                    value={selectedBranchKey} 
                    onChange={handleBranchSelect(id)} 
                    isInvalid={selectedBranchKey === ""}
                  >
                    <option value="" disabled>請選擇</option>
                    {branchesList.map((branchObj, idx) => {
                      return <option key={`${name}-${branchObj.key}-${idx}`} value={branchObj.key}>{branchObj.title}</option>
                    })}
                    {branchesList.length > 1 && <option value={ALL_BRANCHES}>結局全開</option>}
                  </Select>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Divider />
      <HStack justify="center">
        <Button 
          colorScheme="red"
          variant={false ? "solid" : "outline"}
          rightIcon={<FaHandshake />}
          onClick={handleAssignClick}
          disabled={!hasStarted || !hasAnyBranchSelected}
        >
          分配結局
        </Button>
      </HStack>
      {isShowConfirmDialog && renderConfirmDialog()}
    </Stack>
  )
}

export default Endings;