import React from "react";
import {
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  IconButton,
  useColorModeValue
} from "@chakra-ui/react";
import { BiSearch } from 'react-icons/bi';
import { IoMdCloseCircle } from 'react-icons/io';

const KeywordInput = props => {
  const { findKeyword, setFindKeyword } = props;
  const bgColor = useColorModeValue("white", "gray.800");

  const handleInput = e => {
    const input = e.target.value;
    setFindKeyword(input);
  };

  return (
    <InputGroup w="500px" maxW="100%" bg={bgColor}>
      <InputLeftElement children={<BiSearch />} />
      <Input placeholder="輸入至少兩個字以搜尋劇本回憶或者線索內容" value={findKeyword} onChange={handleInput} />
      {findKeyword && (
        <InputRightElement >
          <IconButton
            size="sm"
            variant="ghost"
            borderRadius="full"
            icon={<IoMdCloseCircle/>}
            onClick={() => setFindKeyword('')}
          />
        </InputRightElement>
      )}
    </InputGroup>
  )
};

export default KeywordInput;