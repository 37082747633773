import React, { useState } from "react";
import { 
  Button,
  Input,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import { IoMdSend } from 'react-icons/io';

const NAME_MIN = 2;
const NAME_MAX = 10;

const PlayerLoginWindow = (props) => {
  const { hasStarted, onSubmit, isLoading } = props;
  const [name, setName] = useState('');

  const handleInput = e => {
    const value = e.target.value.replace(/[!@#\$%\^\&*\)\(+=._-]/g, '')

    setName(value.trim());
  }

  const handleSubmit = () => {
    onSubmit(name)
  };

  return (
    <Modal       
      isOpen={true} 
      isCentered 
      size="xl"
      motionPreset="scale"
      onClose={() => {}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="rgba(0, 0, 0, .81)"/>
      <ModalContent>
        <ModalHeader>歡迎使用 LARP Bar - 你的線上玩本好幫手</ModalHeader>
        <ModalBody>
          <Text mb={2}>
            {hasStarted ? "遊戲已經開始，洽主持人如何返回遊戲。如果是重新加入，請輸入一個與先前不同的名字，以供識別。" : "遊戲尚未開始，請讓主持人知道如何稱呼您。"}
          </Text>
          <Input
            size="lg" 
            variant="outline" 
            value={name}
            placeholder={`請輸入 ${NAME_MIN} ~ ${NAME_MAX} 個字元，請勿使用特殊符號`}
            onChange={handleInput}
          />
        </ModalBody>
        <ModalFooter>
          <Button 
            rightIcon={<IoMdSend />}  
            colorScheme="twitter" 
            borderRadius={20}
            variant="solid" 
            onClick={handleSubmit} 
            isDisabled={name.length < NAME_MIN || name.length > NAME_MAX}
            isLoading={isLoading}
          >
            送出
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PlayerLoginWindow;

