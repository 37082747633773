import React, { useMemo, useState } from "react";
import {
  Text,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td, 
  Stack, 
  Button, 
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";

const sceneTypeTextMap = {
  READ: "讀本",
  SEARCH: "蒐證",
  VOTE: "投票"
}

const Scenes = props => {
  const { 
    isBasicInfoReady, 
    staticData, 
    isShowCharactersChoiceWindow,
    hasStarted,    
    currentSceneId, 
    scenesTime, 
    playersStatus, 
    onSceneSet, 
    isLoading, 
    setIsActiveTab 
  } = props;
  const { scenesOrder, sceneMap, characterMap } = staticData;
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
  const [selectedSceneId, setSelectedSceneId] = useState('');

  const currentSceneOrder = useMemo(() => {
    if (currentSceneId && scenesOrder && scenesOrder.length > 0) {
      return scenesOrder.indexOf(currentSceneId);
    } else {
      return 0;
    }
  }, [currentSceneId, scenesOrder]);

  const isReadyCharacters = useMemo(() => {
    const playerIds = Object.keys(playersStatus);

    return playerIds.reduce((accu, playerId, idx) => {
      const { characterId, isReady } = playersStatus[playerId]
      const name = characterMap && characterMap[characterId] ? characterMap[characterId].name : "";

      return isReady ? `${accu}【${name}】${idx !== playerIds.length - 1 ? ", " : ""}` : accu;

    }, '')
  }, [playersStatus]);

  const getTime = (timestamp) => {
    if (timestamp) {
      const d = new Date(timestamp);

      let h = d.getHours().toString();
      let m = d.getMinutes().toString();

      if (m.length === 1) m = `0${m}`

      return { h, m }
    } else {
      return null
    }
  };

  const handleSceneSet = (e) => {
    const sceneId = e.target.value;

    if (sceneId === "INTRO") {
      setIsActiveTab("characters")
    } else {
      setSelectedSceneId(sceneId)
      setIsShowConfirmDialog(true);
    }
  }

  const handleConfirmDialogClose = () => {
    setIsShowConfirmDialog(false);
    setSelectedSceneId('');
  };

  const handleConfirmSet = () => {
    onSceneSet(selectedSceneId)
    handleConfirmDialogClose();
  };

  const handleNavToEndingTab = () => {
    setIsActiveTab("endings")
  };

  const renderConfirmDialog = () => {
    return (
      <Modal 
        isOpen={true} 
        isCentered 
        motionPreset="scale"
        onClose={handleConfirmDialogClose}
        closeOnEsc={true}
        closeOnOverlayClick={true} 
      >
        <ModalOverlay />
        <ModalCloseButton />
        <ModalContent>
          <ModalHeader>確認進入下一幕</ModalHeader>
          <ModalBody>
            <Text fontSize="xl">
              確定要進入「{sceneMap[selectedSceneId].title}」嗎？
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleConfirmDialogClose} disabled={isLoading}>
              取消
            </Button>
            <Button 
              variant="outline" 
              colorScheme="red"
              onClick={handleConfirmSet} 
              isLoading={isLoading}
            >
              確定
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  };

  return (
    <Stack p={5} spacing={5}>
      <Heading size="xl">流程與環節狀況</Heading>
      <Stack>
        <Text fontSize="xl">已準備完成的角色：</Text>
        <Text>{isReadyCharacters}</Text>
      </Stack>
      <Table variant="striped" >
        <Thead>
          <Tr>
            <Th>順序</Th>
            <Th>環節</Th>
            <Th>類型</Th>
            <Th textAlign="center">可蒐證次數</Th>
            <Th textAlign="center">參考時長（分鐘）</Th>
            <Th textAlign="center">開始時間</Th>
            <Th>動作</Th>
          </Tr>
        </Thead>
        <Tbody>
          {(isBasicInfoReady && scenesOrder) && scenesOrder.map((id, idx) => {
            const { title, type, duration, exploreLimit } = sceneMap[id];
            const isBeforeCurrentScene = scenesOrder.indexOf(id) < currentSceneOrder;
            const isCurrentScene = id === currentSceneId;
            const isEnding = id === "ENDING";
            const isDisabled = (idx > 1 ? !hasStarted : !isShowCharactersChoiceWindow) || isBeforeCurrentScene || isCurrentScene;
            const startTime = scenesTime[id] ? getTime(scenesTime[id]) : null;

            return (
              <Tr key={`${id}-${idx}`}>
                <Td>{idx}</Td>
                <Td>{title}</Td>
                <Td>{sceneTypeTextMap[type]}</Td>
                <Td textAlign="center">{exploreLimit}</Td>
                <Td textAlign="center">{duration}</Td>
                <Td textAlign="center">{startTime ? `${startTime.h}:${startTime.m}` : ''}</Td>
                <Td>
                  <Button 
                    colorScheme="red"
                    variant={isBeforeCurrentScene || isCurrentScene ? "solid" : "outline"}
                    isLoading={!isDisabled && isLoading}
                    disabled={isDisabled || isLoading}
                    value={id}
                    onClick={isEnding ? handleNavToEndingTab : handleSceneSet}
                  >
                    {isEnding
                      ? '分配結局'
                      : isBeforeCurrentScene 
                        ? '已結束' 
                        : isCurrentScene 
                          ? '進行中'
                          : '進入'
                    }
                  </Button>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      {isShowConfirmDialog && renderConfirmDialog()}
    </Stack>
  )
}

export default Scenes;