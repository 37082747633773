import React from "react";
import { 
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";

  const NoticeDialog = ({ noticeMessage }) => {

    return (
      <Modal 
        isOpen={true} 
        isCentered 
        motionPreset="scale"
        onClose={() => {}}
        closeOnEsc={false}
        closeOnOverlayClick={false} 
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>主持人公告</ModalHeader>
          <ModalBody>
            <Text fontSize="xl" color="red.600">{noticeMessage}</Text>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    )
  };

  export default NoticeDialog;