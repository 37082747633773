import React, { useEffect, useState, useCallback } from "react";
import { Stack, Link, ListItem, Box, UnorderedList } from "@chakra-ui/react";
import { KeywordInput } from './';
import { createMarkup } from '../../utils';

const FIND_LOWER_LIMIT = 2;
const LETTERS = 20;

const Finder = props => {
  const { 
    findKeyword,
    setFindKeyword,
    sceneIdList, 
    sceneMap, 
    onSelectScene,
    clueIdList, 
    clueMap,
    onSelectCategory,
    onSelectClue,
    onMenuTabChange,
  } = props;

  const [result, setResult] = useState([]);

  useEffect(() => {
    if (findKeyword.length >= FIND_LOWER_LIMIT) {
      handleFindResult(findKeyword);
    } else {
      setResult([]);
    }
  }, [findKeyword]);

  const handleFindResult = (input) => {
    const regex = new RegExp(input);

    let result = [];

    sceneIdList.filter(sceneId => sceneMap[sceneId].type === "READ")
      .forEach(sceneId => {
        const content = sceneMap[sceneId].content;
        const trimedContent = content.replaceAll(/<[^>]*>?/gm, '')
        if (regex.test(trimedContent)) {
          const matchesArray = getRelation(
            genSearchResultsHTMLArray(trimedContent, input), 
            { source: "scenes", sceneId }
          )
          result = [...result, ...matchesArray];
        }
      });

    clueIdList.forEach(clueId => {
      const content = clueMap[clueId].content;
      const trimedContent = content.replaceAll(/<[^>]*>?/gm, '')
      if (regex.test(trimedContent)) {
        const matchesArray = getRelation(
          genSearchResultsHTMLArray(trimedContent, input), 
          { source: "clues", clueId }
        )
        result = [...result, ...matchesArray];
      }
    })

    setResult(result);
  };

  const genSearchResultsHTMLArray = useCallback((content, keyword, result = []) => {
    let array = [];
    if (result.length > 0) {
      array = result;
    }
    const searchIndex = content.search(keyword);

    if (searchIndex > -1) {
      const startIndex = searchIndex - LETTERS >= 0 ? searchIndex - LETTERS : 0;
      const endIndex = searchIndex + keyword.length + LETTERS;
      const sentence = content.slice(startIndex, endIndex);

      array.push(sentence.replace(keyword, `<span class="keyword">${keyword}</span>`));

      genSearchResultsHTMLArray(content.slice(searchIndex + keyword.length), keyword, array)
    }

    return array;
  }, []);

  const getRelation = useCallback((array = [], relation = {}) => {
    return array.map(text => {
      return {
        text,
        ...relation
      }
    });
  }, []);

  const handleSourceClick = (data) => {
    if (data.source === "scenes") { 
      onSelectScene(data.sceneId);
      onMenuTabChange("scenes");
    } else if (data.source === "clues") {
      onSelectCategory(clueMap[data.clueId].category)
      onSelectClue(data.clueId)
      onMenuTabChange("clues");
    }
  };

  const renderFindResults = () => {
    if (result.length === 0) return;

    return (
      <UnorderedList p={6}>
        {result.map((data, idx) => {
          const { text, source } = data;
          let sourceText = ""
          if (source === "scenes") {
            sourceText = `《${sceneMap[data.sceneId].title}》`
          } else if (source === "clues") {
            sourceText = `${clueMap[data.clueId].category}－${clueMap[data.clueId].shorthand}`
          }

          return (
            <ListItem pb={4} key={`${text}-${idx}`}>
              <Link onClick={() => handleSourceClick(data)}>
                {sourceText}：...<span dangerouslySetInnerHTML={createMarkup(text)} />...
              </Link>
            </ListItem>
          )
        })}
      </UnorderedList>
    )
  };

  return (
    <Stack px={4} minH="100%">
      <Box pos="sticky" top={4} zIndex="20">
        <KeywordInput findKeyword={findKeyword} setFindKeyword={setFindKeyword} />
      </Box>
      {renderFindResults()}
    </Stack>
  )
};

export default Finder;