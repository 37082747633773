import React, { useMemo, useState } from "react";
import {
  Text,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td, 
  Stack,
  HStack,
  UnorderedList,
  ListItem,
  Divider,
  IconButton,
} from "@chakra-ui/react";
import { BiFilter } from 'react-icons/bi';

const Votes = props => {
  const { isBasicInfoReady, staticData, votes, playersStatus } = props;
  const { voteIdList, voteMap, sceneMap, characterMap } = staticData;
  const [sorter, setSorter] = useState("characterId");

  const playerIds = Object.keys(playersStatus);

  const isReadyCharacters = useMemo(() => {
    return playerIds.reduce((accu, playerId, idx) => {
      const { characterId, isReady } = playersStatus[playerId];
      const name = characterMap && characterMap[characterId] ? characterMap[characterId].name : "";

      return isReady ? `${accu}【${name}】${idx !== playerIds.length - 1 ? ", " : ""}` : accu;
    }, '')
  }, [playersStatus]);

  const sortedVoteIdList = useMemo(() => {
    return voteIdList.sort((a, b) => {
        return voteMap[a][sorter].localeCompare(voteMap[b][sorter])
      }).sort((a, b) => {
        if (voteMap[a][sorter] === voteMap[b][sorter]) {
          return voteMap[a].number - voteMap[b].number
        }
      })
  }, [sorter]);
  
  const organizedVoteMap = useMemo(() => {
    if (!voteIdList) return {};

    const playerIds = Object.keys(playersStatus);

    return voteIdList.reduce((accu, voteId) => {
      const { voteId: sceneId, characterId: voteChId, number, options } = voteMap[voteId];
      const answers = playerIds.map(playerId => {
        const { characterId } = playersStatus[playerId];

        if (voteChId !== "" && voteChId !== characterId) return null;

        const name = characterMap && characterMap[characterId] ? characterMap[characterId].name : "";
        let optionList = [];
        let totalScore = 0;
        if (votes && votes[sceneId] && votes[sceneId][number] && votes[sceneId][number][playerId]) {
          optionList = Object.keys(votes[sceneId][number][playerId])
            .filter(answer => {
              const value = votes[sceneId][number][playerId][answer];
              if (value === true) {
                const option = options.find(ele => ele.name === answer);

                if (option && option.score) totalScore += option.score;

                return true;
              } else {
                return false;
              }
            });
        }
        return [name, optionList, totalScore];
      });
      
      return {
        ...accu,
        [voteId]: {
          ...voteMap[voteId],
          answers
        }
      }
    }, {})
  }, [voteIdList, votes]);

  const totalScoreList = useMemo(() => {
    const characterTotalScoreList = playerIds.map(playerId => {
      let score = 0;
      const { characterId } = playersStatus[playerId];
      const name = characterMap && characterMap[characterId] ? characterMap[characterId].name : null;

      if (name) {
        voteIdList.forEach(voteId => {
          const { characterId: chId , answers } = organizedVoteMap[voteId];
  
          if (chId === characterId || chId === '') {
            const characterAnswer = answers.find(answerArray => Array.isArray(answerArray) ? answerArray[0] === name : false);
            score += characterAnswer[2];
          }
        })

        return { 
          name,
          score 
        }
      }
    });

    characterTotalScoreList.sort((a, b) => b.score - a.score)

    return characterTotalScoreList;
  }, [votes]);

  const genOptionString = array => {
    return array.reduce((accu, text, idx) => {
      return `${accu}${text}${idx !== array.length -1 ? ", ": ""}`
    }, "");
  };

  return (
    <Stack p={5} spacing={5}>
      <Heading size="xl">投票狀況</Heading>
      <Stack>
        <Text fontSize="xl">已宣稱此輪投票完畢的角色：</Text>
        <Text>{isReadyCharacters}</Text>
      </Stack>
      <Text fontSize="xl">此輪投票即時狀況</Text>
      <Table variant="striped" >
        <Thead>
          <Tr>
            <Th>環節</Th>
            <Th>
              <HStack>
                <Text>回答者</Text>
                <IconButton 
                  ml={2}
                  size="xs"
                  variant="outline"
                  icon={<BiFilter/>}
                  onClick={() => setSorter("characterId")}
                />
              </HStack>
            </Th>
            <Th>
              <HStack>
                <Text>題目</Text>
                <IconButton 
                  ml={2}
                  size="xs"
                  variant="outline"
                  icon={<BiFilter/>}
                  onClick={() => setSorter("topic")}
                />
              </HStack>
            </Th>
            <Th>角色回答與得分</Th>
          </Tr>
        </Thead>
        <Tbody>
          {(isBasicInfoReady && sortedVoteIdList) && sortedVoteIdList.map((id, idx) => {
            const { topic, characterId, voteId: sceneId, answers } = organizedVoteMap[id];

            return (
              <Tr key={id}>
                <Td>{sceneMap && sceneMap[sceneId] ? sceneMap[sceneId].title : ""}</Td>
                <Td>{characterId && characterMap[characterId] ? characterMap[characterId].name : "所有人"}</Td>
                <Td>{topic}</Td>
                <Td>
                  <UnorderedList>
                    {answers.map((answer, idx) => {
                      if (!Array.isArray(answer)) return;

                      const optionString = genOptionString(answer[1]);
                      return (
                        <ListItem key={`ch-${answer[0]}-${idx}`}>
                          {answer[0]}：{optionString} {`${optionString ? `(${answer[2]})` : ''}`}
                        </ListItem>)
                    })}
                  </UnorderedList>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Divider />
      <Text fontSize="xl">總得分</Text>
      <Table variant="striped" >
        <Thead>
          <Tr>
            <Th>名次</Th>
            <Th>角色</Th>
            <Th>總得分</Th>
          </Tr>
        </Thead>
        <Tbody>
          {totalScoreList.map((scoreData, idx) => {
            const { name, score } = scoreData

            return (
              <Tr key={`${name}-${score}-${idx}`}>
                <Td>{idx + 1}</Td>
                <Td>{name}</Td>
                <Td>{score}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Stack>
  )
}

export default Votes;