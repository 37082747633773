import React, { useState } from "react";
import {
  Stack,
  HStack,
  Heading, 
  Text,
  Divider,
  ListItem, 
  OrderedList, 
  UnorderedList,
  Link,
  Icon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import { BiLinkExternal } from 'react-icons/bi';
import { trackEvent } from '../../utils';
import { auth } from '../../firebase';

const Help = ({ isAboveSm, staticData }) => {
  const { scenarioId, title, providerName } = staticData;
  const [isShowLogoutDialog, setIsShowLogoutDialog] = useState(false);
  
  const handleUrlClick = () => {
    trackEvent('outbound_link_click', {
      scenario_id: scenarioId,
      target_value: 'Escapebar',
      event_category: 'Escapebar',
      event_label: `${providerName}-${title}`
    })
  };

  const handleRequestLogout = () => {
    setIsShowLogoutDialog(true);
  };

  const handleConfirmLogout = () => {
    auth.signOut();
    window.location.reload();
  };

  const renderLogout = () => {
    return (
      <Modal 
        isOpen={isShowLogoutDialog} 
        isCentered 
        motionPreset="scale"
        onClose={() => setIsShowLogoutDialog(false)}
        closeOnEsc={true}
        closeOnOverlayClick={true} 
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>確定要重新登入嗎？</ModalHeader>
          <ModalBody>
            <HStack justify="center">
              <Button 
                onClick={() => setIsShowLogoutDialog(false)}
                colorScheme="gray"
                variant="outline"
              >
                取消
              </Button>
              <Button 
                onClick={handleConfirmLogout}
                colorScheme="twitter"
                variant="solid"
              >
                確定
              </Button>
            </HStack>

          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    )
  };

  return (
    <Stack p={4}>
      <Stack>
        <Heading size={isAboveSm ? "lg" : "md" }>LARP Bar 使用說明與提示</Heading>
        <OrderedList p={4} spacing={4}>
          <ListItem>閱讀劇本時，可以選取重點文字按下「畫重點」，重點文字會螢光筆上色並且會自動收入筆記本中的「重點畫記」。重點畫記可以在筆記本中移除。</ListItem>
          <ListItem>劇本畫重點沒反應！要不要試試畫少一點的字呢？</ListItem>
          <ListItem>閱讀劇本時，按下<Text as="span" color="green">「我好了」</Text>可以讓主持人知道您目前已經準備好進入下一幕。</ListItem>
          <ListItem>探索線索時，輸入代碼無分大小寫；解碼線索時，輸入代碼<Text as="span" color="red">有分</Text>大小寫。</ListItem>
          <ListItem>閱讀線索時，可以點擊「收藏」，會收入線索夾的「我的收藏」。已收藏的線索可以在「我的收藏」中移除。</ListItem>
          <ListItem>覺得劇本畫重點很方便嗎？在電腦上也可以針對線索內容畫重點喔！（手機版暫時不支援）</ListItem>
          <ListItem>忘記在哪裡看過某個關鍵字嗎？善用搜尋功能幫您回憶劇本與線索，不必大海撈針。</ListItem>
          <ListItem>白底閱讀大量文字讓您感到眼睛不適嗎？您可以嘗試切換到深色模式。</ListItem>
          <ListItem>電腦版背景音樂會在載入完成後自動播放，您可以自行調整至您覺得舒適的音量；手機版如果音樂沒有自動播放，請按右上角的播放鍵，並自行調整裝置音量。</ListItem>
          <ListItem>遇到劇本或者線索消失等狀況，通常重新整理 (Windows 按 F5, mac 按 cmd + R) 可以解決。</ListItem>
          <ListItem>在同一個瀏覽器分頁中，斷線重連或者重新整理不需要再次輸入使用者名稱即可回復資料。</ListItem>
          <ListItem>遊戲開始後，若因設備問題需要使用不同瀏覽器或者裝置進入遊戲，請輸入新的名字並且透過聊天平台告知主持人，方便您取回劇本與線索資料。請注意：筆記與收藏不會同步轉移。</ListItem>
          <ListItem>使用上有其他問題，請隨時透過聊天平台詢問主持人。</ListItem>
          <ListItem onClick={handleRequestLogout}><Link color="gray.400">若主持人建議重新登入時，請點此。</Link></ListItem>
        </OrderedList>
      </Stack>
      <Divider/>
      <Stack>
        <Heading size={isAboveSm ? "lg" : "md" }>關於 LARP Bar</Heading>
        <UnorderedList p={4} spacing={4}>
          <ListItem>LARP Bar 是協助進行線上 LARP 的工具平台，希望盡可能還原現場遊玩 LARP 的樂趣。</ListItem>
          <ListItem>我們支持正版劇本，所有您在遊戲中瀏覽的劇本內容皆由 LARP 業者提供，LARP Bar 僅代為上傳並提供給玩家在遊戲期間中瀏覽。</ListItem>
          <ListItem>請勿隨意將您在 LARP Bar 中取得的劇本內容透過任何形式複製或再製，避免衍生版權相關問題。</ListItem>
          <ListItem>有任何問題或者建議請來信 service@escape.bar</ListItem>
          <ListItem>
            LARP Bar 為全台灣最詳盡實境遊戲資訊平台
            <Link 
              mx={2} 
              href="http://escape.bar/?utm_source=LARPBar&utm_medium=referral&utm_campaign=LARPBar" 
              isExternal 
              onClick={handleUrlClick}
            >
              逃脫吧 - Escapebar <Icon as={BiLinkExternal} mx="2px" />
            </Link>
            旗下服務。
          </ListItem>
        </UnorderedList>
      </Stack>
      {isShowLogoutDialog && renderLogout()}
    </Stack>
  )
};

export default Help;