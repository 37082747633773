import React, { useEffect, useState, useRef } from "react";
import { 
  Flex,   
  Stack, 
  Box, 
  Heading, 
  Text, 
  Button,
  IconButton,
  Divider,
  Link,
  useColorModeValue
} from "@chakra-ui/react";
import { BiTrash } from 'react-icons/bi';

const emptyArray = [];
const NOTE_FIELD_MIN_HEIGHT = 200;

// TODO: dnd

const Notebook = props => {
  const { 
    isAboveSm,
    sceneMap,
    onSelectScene,
    clueMap,
    onSelectCategory,
    onSelectClue,
    onMenuTabChange
  } = props;
  const [highlightList, setHighlightList] = useState(emptyArray);
  const [note, setNote] = useState('');

  const noteRef = useRef(null);
  
  const bgColor = useColorModeValue("gary.50", "gray.900");

  useEffect(() => {
    const highlightInStorage = JSON.parse(localStorage.getItem("highlight")) || emptyArray;
    const noteInStorage = JSON.parse(localStorage.getItem("note")) || '';

    setHighlightList(highlightInStorage);
    setNote(noteInStorage);
  }, []);

  const handleHighlightDelete = (target) => {
    let highlightInStorage = []; 
    let newHighlight = [];
    if (target !== "ALL") {
      highlightInStorage = localStorage.getItem("highlight") 
        ? JSON.parse(localStorage.getItem("highlight")) 
        : []
        ;
      newHighlight = highlightInStorage.filter(data => data.text !== target);
    }

    setHighlightList(newHighlight);
    localStorage.setItem("highlight", JSON.stringify(newHighlight));
  };

  const handleSourceClick = (data) => {
    if (data.source === "scenes") { 
      onSelectScene(data.sceneId);
      onMenuTabChange("scenes");
    } else if (data.source === "clues") {
      onSelectCategory(clueMap[data.clueId].category)
      onSelectClue(data.clueId)
      onMenuTabChange("clues");
    }
  };

  const renderHighlightContent = () => {
    return highlightList.map(data => {
      const { text, source } = data;
      let sourceText = ""
      if (source === "scenes") {
        sourceText = `《${sceneMap[data.sceneId].title}》`
      } else if (source === "clues") {
        sourceText = `${clueMap[data.clueId].category}－${clueMap[data.clueId].shorthand}`
      }
      return (
        <Flex
          justify="space-between"
          p={2}
          borderWidth="1px" 
          borderRadius={4} 
          key={text}
        >
          <Text>
            <Link onClick={() => handleSourceClick(data)}>{sourceText}</Link>
            ：{text}
          </Text>
          <IconButton
            colorScheme="red"
            size="xs"
            variant="solid"
            icon={<BiTrash />}
            borderRadius="full"
            onClick={() => handleHighlightDelete(text)}
          />
        </Flex>
      )
    });
  };

  const renderNote = () => {
    return (
      <Box 
        as="textarea" 
        p={2} 
        minH={`${NOTE_FIELD_MIN_HEIGHT}px`}
        borderWidth="1px" 
        borderRadius="4px"
        bg={bgColor}
        onChange={handleInput} 
        value={note} 
        ref={noteRef}
      />
    )
  };

  const handleInput = e => {
    const content = e.target.value;
    if (!content) {
      noteRef.current.style.height = `${NOTE_FIELD_MIN_HEIGHT}px`
    } else {
      noteRef.current.style.height = `${noteRef.current.scrollHeight}px`;
    }

    setNote(content)
    localStorage.setItem("note", JSON.stringify(content));
  };

  return (
    <Stack w="100%" h="100%" p={2} spacing={3} pos="relative">
      <Stack>
        <Flex justify="space-between" align="center" pr={2}>
          <Heading size={isAboveSm ? "lg" : "md"}>重點畫記</Heading>
          {highlightList.length > 0 && (
            <Button            
              colorScheme="red"
              size="xs"
              variant="solid"
              borderRadius={20}
              rightIcon={<BiTrash />}
              onClick={() => handleHighlightDelete("ALL")}
            >
              全部清除
            </Button>
          )}

        </Flex>
        {renderHighlightContent()}
      </Stack>
      <Divider />
      <Stack>
        <Heading size={isAboveSm ? "lg" : "md"}>隨手筆記</Heading>
        {renderNote()}
      </Stack>
    </Stack>
  )
};

export default Notebook;