import React from "react";
import { 
  Flex,
  Box, 
  HStack, 
  IconButton, 
  Image, 
  useColorMode,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import { TiInfoLargeOutline } from 'react-icons/ti'
import { FaMoon, FaSun } from 'react-icons/fa';
import { BiHelpCircle } from 'react-icons/bi';
import { BsVolumeUpFill, BsVolumeMuteFill } from 'react-icons/bs';
import { trackEvent } from '../../utils';

const SLIDER_RANGE = 100;

const Header = props => {
  const { 
    scenarioId = '',
    isActiveTab = '', 
    onClick,
    hasBgMusic,
    bgVolume,
    setBgVolume,
    staticData
  } = props;
  const { title, providerName } = staticData;

  const { colorMode, toggleColorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";
  const bgColor = useColorModeValue("white", "gray.800");

  const isBgMusicMute = bgVolume === 0;

  const handleVolumeChange = val => {
    setBgVolume(val / SLIDER_RANGE)
  }

  const handleClick = tabName => {
    trackEvent('menu_button_click', {
      scenario_id: scenarioId,
      target_value: tabName,
      event_category: tabName,
      event_label: `${providerName}-${title}`
    })

    if (tabName === "color") {
      toggleColorMode()
    } else if (tabName === "volume") {
      return;
    } else {
      onClick(tabName);
    }
  };

  return (
    <Flex pos="fixed" top="0" w="100vw" zIndex={100} px={1} justify="space-between" bg={bgColor} boxShadow="base">
      <HStack w="33%">
        <IconButton 
          colorScheme="twitter"
          size="sm"
          variant="ghost"
          borderRadius="full"
          icon={<TiInfoLargeOutline/>}
          isActive={isActiveTab === "info"}
          onClick={() => handleClick("info")}
        />
        <IconButton 
          colorScheme="twitter"
          size="sm"
          variant="ghost"
          borderRadius="full"
          icon={<BiHelpCircle/>}
          isActive={isActiveTab === "help"}
          onClick={() => handleClick("help")}
        />
      </HStack>
      <Box flexGrow="1" align="center">
        <Image w="88px" src={`/menu-logo.png`} />
      </Box>
      <HStack w="33%" justify="flex-end">
        {hasBgMusic && (
          <Popover placement="bottom">
            <PopoverTrigger>
              <IconButton 
                colorScheme="twitter"
                size="sm"
                variant="ghost"
                borderRadius="full"
                leftIcon={isBgMusicMute ? <BsVolumeMuteFill />: <BsVolumeUpFill />} 
                onClick={() => handleClick('volume')}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <Slider colorScheme="twitter" aria-label="slider-ex-1" value={bgVolume * SLIDER_RANGE} onChange={handleVolumeChange}>
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
        <IconButton 
          colorScheme="twitter"
          size="sm"
          variant="ghost"
          borderRadius="full"
          icon={isDarkMode ? <FaSun />: <FaMoon />}
          onClick={() => handleClick('color')}
        />
      </HStack>
    </Flex>
  )
};

export default Header;