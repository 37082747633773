import React from "react";
import { 
  Flex, 
  Stack, 
  VStack, 
  Button, 
  Image, 
  useColorMode, 
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import { BsPeopleFill, BsVolumeUpFill, BsVolumeMuteFill } from 'react-icons/bs';
import { GiOpenBook } from 'react-icons/gi'
import { CgSearchLoading } from 'react-icons/cg';
import { TiInfoLargeOutline } from 'react-icons/ti'
import { FaEdit, FaMoon, FaSun } from 'react-icons/fa';
import { BiHelpCircle, BiFileFind } from 'react-icons/bi';
import { trackEvent } from '../../utils';

const SLIDER_RANGE = 100;

const Menu = props => {
  const { 
    scenarioId = '',
    isBasicInfoReady = false, 
    hasAnyScene = false,
    isActiveTab = '', 
    onClick,
    isAboveSm,
    bgVolume,
    setBgVolume,
    staticData
  } = props;

  const { title, providerName } = staticData;

  const { colorMode, toggleColorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";
  const bgColor = useColorModeValue("white", "gray.800");

  const isBgMusicMute = bgVolume === 0;

  const handleVolumeChange = val => {
    setBgVolume(val / SLIDER_RANGE)
  }

  const handleClick = tabName => {
    trackEvent('menu_button_click', {
      scenario_id: scenarioId,
      target_value: tabName,
      event_category: tabName,
      event_label: `${providerName}-${title}`
    })

    if (tabName === "color") {
      toggleColorMode()
    } else if (tabName === "volume") {
      return;
    } else {
      onClick(tabName);
    }
  };

  return (
    <Stack 
      pos={{ base: "fixed", sm: "relative" }}
      bottom="0"
      h={{ base: "auto", sm: "100%" }}
      w={{ base: "100vw", sm: "auto" }}
      zIndex={100}
      p={[0, 1, 2]}
      bg={bgColor} 
      borderRightWidth="1px" 
      flexDirection={{ base: "row", sm: "column" }} 
      justify="space-between"
      
    >
      <Flex w="100%" flexDirection={{ base: "row", sm: "column" }} justify="space-between" boxShadow={isAboveSm ? "" : "inner"}>
        <Image d={{ base: "none", sm: "inline" }}w={{ base: "0", sm: "88px" }} src={`/menu-logo${isAboveSm ? '' : '-mobile'}.png`} />
        <Button 
          d={{ base: "none", sm: "flex" }}
          my={[0, 1]}
          colorScheme="twitter" 
          variant="ghost" 
          leftIcon={<TiInfoLargeOutline />} 
          isActive={isActiveTab === "info"}
          onClick={() => handleClick("info")}
        >
          {isAboveSm && "關於"}
        </Button>
        <Button 
          my={[0, 1]}
          colorScheme="twitter" 
          variant="ghost" 
          leftIcon={<BsPeopleFill />} 
          isActive={isActiveTab === "characters"}
          isDisabled={!isBasicInfoReady}
          onClick={() => handleClick("characters")}
        >
          {(isActiveTab === "characters" || isAboveSm) && "角色"}
        </Button>
        <Button 
          my={[0, 1]}
          colorScheme="twitter" 
          variant="ghost" 
          leftIcon={<GiOpenBook />} 
          isActive={isActiveTab === "scenes"}
          isDisabled={!isBasicInfoReady || !hasAnyScene}
          onClick={() => handleClick("scenes")}
        >
          {(isActiveTab === "scenes" || isAboveSm) && "劇本"}
        </Button>
        <Button 
          my={[0, 1]}
          colorScheme="twitter" 
          variant="ghost" 
          leftIcon={<CgSearchLoading />} 
          isActive={isActiveTab === "clues"}
          isDisabled={!isBasicInfoReady}
          onClick={() => handleClick("clues")}
        >
          {(isActiveTab === "clues" || isAboveSm) && "線索"}
        </Button>
        <Button 
          my={[0, 1]}
          colorScheme="twitter" 
          variant="ghost" 
          leftIcon={<FaEdit />} 
          isActive={isActiveTab === "notebook"}
          isDisabled={!isBasicInfoReady}
          onClick={() => handleClick("notebook")}
        >
          {(isActiveTab === "notebook" || isAboveSm) && "筆記"}
        </Button>
        <Button 
          my={[0, 1]}
          colorScheme="twitter" 
          variant="ghost" 
          leftIcon={<BiFileFind />} 
          isActive={isActiveTab === "finder"}
          isDisabled={!isBasicInfoReady || !hasAnyScene}
          onClick={() => handleClick("finder")}
        >
          {(isActiveTab === "finder" || isAboveSm) && "搜尋"}
        </Button>
      </Flex>
      <VStack d={{ base: "none", sm: "flex" }}>
        <Popover placement="right">
          <PopoverTrigger>
            <Button 
              my={[0, 1]}
              colorScheme="twitter" 
              variant="ghost" 
              leftIcon={isBgMusicMute ? <BsVolumeMuteFill />: <BsVolumeUpFill />} 
              onClick={() => handleClick('volume')}
            >
            音量
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <Slider colorScheme="twitter" aria-label="slider-ex-1" value={bgVolume * SLIDER_RANGE} onChange={handleVolumeChange}>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <Button 
          my={[0, 1]}
          colorScheme="twitter" 
          variant="ghost" 
          leftIcon={isDarkMode ? <FaSun />: <FaMoon />} 
          onClick={() => handleClick('color')}
        >
          {isAboveSm ?  isDarkMode ? "淺色" : "深色" : ""}
        </Button>
        <Button 
          my={[0, 1]}
          colorScheme="twitter" 
          variant="ghost" 
          leftIcon={<BiHelpCircle />} 
          isActive={isActiveTab === "help"}
          onClick={() => handleClick("help")}
        >
          {isAboveSm && "說明"}
        </Button>
      </VStack>
    </Stack>
  )
};

export default Menu;