import React, { useState } from "react";
import { 
  Center,
  Button,
  Text,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputLeftAddon,
  Input
} from "@chakra-ui/react";
import { FcGoogle } from 'react-icons/fc';
import { googleSignIn } from '../../firebase';

const HostLoginWindow = (props) => {
  const { roomName, userName, usingHostName, isLoading, onEnter } = props;
  const [name, setName] = useState('');

  const handleNameInput = e => {
    setName(e.target.value);
  };

  const handleStart = () => {
    onEnter(name)
  };

  return (
    <Modal       
      isOpen={true} 
      isCentered 
      size="xl"
      motionPreset="scale"
      onClose={() => {}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="rgba(0, 0, 0, .81)"/>
      <ModalContent>
        <ModalHeader>歡迎使用 LARP Bar - 你的線上主持好幫手</ModalHeader>
        <ModalBody>
          <Stack mb={4}>
            <Text>你現在要進入的是【{roomName}】 的主持後台</Text>
            {usingHostName && <Text color="blue.500">目前此房間由 {usingHostName} 主持中</Text>}
            {!userName && <Text textAlign="center">請使用 Google 登入驗證身份</Text>}
            {userName && (
              <InputGroup>
                <InputLeftAddon children="我是" />
                <Input
                  value={name}
                  onChange={handleNameInput}
                  isDisabled={isLoading}
                />
              </InputGroup>
            )}
            <Center>
              <Button 
                w="50%"
                colorScheme={userName ? "red" : ""}
                leftIcon={userName ? null : <FcGoogle />}  
                borderRadius={6}
                variant={userName ? "solid" : "outline"}
                onClick={userName ? handleStart : googleSignIn} 
                isDisabled={isLoading || (userName && name.length === 0)}
                isLoading={isLoading}
                letterSpacing="2px"
              >
                {userName ? "開始主持" : "Google"}
              </Button>
            </Center>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default HostLoginWindow;

