import React, { useEffect, useRef, useState, useMemo } from "react";
import { 
  Flex, 
  VStack, 
  HStack, 
  Center, 
  Box, 
  Image, 
  Heading, 
  Text, 
  Button,
  ButtonGroup,
  Divider,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue
} from "@chakra-ui/react";
import { BiSearchAlt } from 'react-icons/bi';
import { FaSearchengin, FaStar, FaRegStar } from 'react-icons/fa';
import { RiFocus3Line, RiMarkPenFill, RiHammerLine } from 'react-icons/ri';
import { BsFillChatDotsFill } from 'react-icons/bs';
import { KeywordInput } from './';
import { createMarkup, getSelectedText, getContentWithKeyword, trackEvent } from '../../utils';

const MY_COLLECTION = "COLLECTION";
const DEFAULT_CLUE_THUMB_IMAGE_PATH = "/clue-thumb.png"

const Clues = props => {
  const { 
    scenarioId = '',
    isAboveSm,
    isBasicInfoReady = false,
    staticData = {},
    clueIdList = [], 
    clueMap = {}, 
    categoryList = [],
    explorableCount = 0,
    selectedCategory = '',
    onSelectCategory,
    selectedClue = '',
    onSelectClue,
    onDig,
    onDecode,
    onUse,
    onExplore,
    onDeliver,
    isForbidden = false,
    isLoading = false,
    handleToastShow,
    findKeyword,
    setFindKeyword,
  } = props;
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [useClueId, setUseClueId] = useState("");
  const [collectionIdList, setCollectionIdList] = useState([]);
  const [isShowPopover, setIsShowPopover] = useState(false);
  const [popoverPostion, setPopoverPosition] = useState([0, 0]);
  const selectedTextRef = useRef(null);
  const selectedClueCurrentIndexRef = useRef(null);  // for keyboard navigation
  const clueIdsInSelectedCategoryRef = useRef(null); // for keyboard navigation
  const ytRef = useRef(null);

  const bgColor = useColorModeValue("white", "gray.800");

  const highlightInStorage = JSON.parse(localStorage.getItem("highlight")) || [];

  const clueIdListInSelectedCategory = useMemo(() => {
    const newList = clueIdList.filter(clueId => clueMap[clueId].category === selectedCategory);
    clueIdsInSelectedCategoryRef.current = newList;
    return newList
  }, [clueIdList, clueMap, selectedCategory]);

  useEffect(() => {
    const collectionIdInStorage = JSON.parse(localStorage.getItem("collection")) || [];

    setCollectionIdList(collectionIdInStorage);

    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  useEffect(() => {
    // for keyboard navigation
    selectedClueCurrentIndexRef.current = 0;
  }, [selectedCategory]);

  useEffect(() => {
    // for keyboard navigation
    selectedClueCurrentIndexRef.current = clueIdListInSelectedCategory.indexOf(selectedClue);
  }, [selectedClue, clueIdListInSelectedCategory])

  useEffect(() => {
    if (ytRef.current) {
      ytRef.current.style.width = "100%";
    }
  }, [ytRef.current])

  const handleKeydown = e => {
    switch (e.key) {
      case "Up":
      case "ArrowUp":
      case "Left":
      case "ArrowLeft":
        if (selectedClueCurrentIndexRef.current < 1) return;
        onSelectClue(clueIdsInSelectedCategoryRef.current[selectedClueCurrentIndexRef.current - 1]);
        break;
      case "Down":
      case "ArrowDown":
      case "Right":
      case "ArrowRight":        
        if (selectedClueCurrentIndexRef.current >= clueIdsInSelectedCategoryRef.current.length - 1) return;
        onSelectClue(clueIdsInSelectedCategoryRef.current[selectedClueCurrentIndexRef.current + 1]);
        break;
    }
  };

  const handleCategorySelect = (categoryName) => {
    onSelectCategory(categoryName);

    if (categoryName === MY_COLLECTION) {
      if (collectionIdList.length) {
        onSelectClue(collectionIdList[0])
      }
    } else {
      const clueIdListInThisCategory = clueIdList.filter(clueId => clueMap[clueId].category === categoryName);

      if (clueIdListInThisCategory.length) {
        onSelectClue(clueIdListInThisCategory[0])
      }
    }
  };

  const handleUse = id => {
    setIsShowConfirm(true);
    setUseClueId(id);
  };

  const handleConfirmUse = () => {
    onUse(useClueId);
    handleCancelUse();
  };

  const handleCancelUse = () => {
    setIsShowConfirm(false);
    setUseClueId("")
  };

  const handleTextSelect = e => {
    const selectedText = getSelectedText();
    if (selectedText) {
      setIsShowPopover(true);
      setPopoverPosition([e.pageX, e.pageY]);
      selectedTextRef.current = selectedText;
    } else {
      setIsShowPopover(false);
    }
  };

  const handleCollect = (type, clueId) => {
    const collectionIdInStorage = JSON.parse(localStorage.getItem("collection")) || [];

    const newCollectionIdList = type === "ADD" 
      ? [...collectionIdInStorage, clueId]
      : collectionIdInStorage.filter(id => id !== clueId)
      ;

    setCollectionIdList(newCollectionIdList);
    localStorage.setItem("collection", JSON.stringify(newCollectionIdList));
  };

  const handleHighlightSave = () => {
    const newHighlightData = {
      source: "clues",
      clueId: selectedClue,
      category: selectedCategory,
      text: selectedTextRef.current
    }

    const newHighlight = [...highlightInStorage, newHighlightData];
    localStorage.setItem("highlight", JSON.stringify(newHighlight));

    setIsShowPopover(false);
    selectedTextRef.current = '';
    handleToastShow("HIGHLIGHT_SAVE_SUCCESS")

    trackEvent('text_highlight', {
      scenario_id: scenarioId
    });
  };

  const getHighlightContent = (content, targetId) => {
    let newContent = content;
    
    if (highlightInStorage) {
      const dataArray = highlightInStorage.filter(data => data.source === "clues" && data.clueId === targetId);
      if (dataArray) {
        dataArray.forEach(data => {
          newContent = newContent.replace(data.text, `<span class="highlight">${data.text}</span>`)
        })
      }
    }

    return newContent
  };

  const renderCategoryList = () => {
    const hasReadClueIdsInStorage = window.localStorage.getItem("hasReadClueIds");
    const hasReadClueIdsArray = hasReadClueIdsInStorage ? JSON.parse(hasReadClueIdsInStorage) : [];

    return (
      <Flex mx={{ base: 2, md: 4 }} minW="fit-content" flexDirection="column">
        {staticData.isClueExplorable && (
          <Button 
            w={isAboveSm ? "96px" : "72px"}
            mt={1}
            mx="auto"
            p={3}
            rightIcon={<BiSearchAlt />} 
            colorScheme="orange" 
            variant="solid" 
            borderRadius={20}
            onClick={() => onExplore('explore')}
            disabled={isForbidden || explorableCount <= 0}
          >
            探索
          </Button>
        )}
        {categoryList.length > 0 && (
          <VStack 
            h="fit-content" 
            mt={10} 
            zIndex="10" 
            bg={bgColor}
            borderWidth="1px" 
            borderRadius={4} 
            shadow="md"
          >
            <Box px={3} py={2} borderBottom="1px solid">
              <Text>類別</Text>
            </Box>
            {collectionIdList.length && (
              <Box px={3} py={2} onClick={() => handleCategorySelect(MY_COLLECTION)}>
                <Text color={selectedCategory === MY_COLLECTION ? "#1A94DA" : ""}>
                  我的收藏
                </Text>
              </Box>
            )}
            {categoryList.map((categoryName, idx) => {
              const hasAnyClueUnread = clueIdList
                .filter(clueId => clueMap[clueId].category === categoryName)
                .some(clueId => hasReadClueIdsArray.indexOf(clueId) === -1);
              
              return (
                <Box px={3} py={2} onClick={() => handleCategorySelect(categoryName)} key={`category-${idx}`}>
                  <Text 
                    color={selectedCategory === categoryName ? "#1A94DA" : ""} 
                    className={hasAnyClueUnread ? "scene--unread" : ""}
                  >
                    {categoryName}
                  </Text>
                </Box>
              )
            })}
          </VStack>
        )}
      </Flex>
    )
  };

  const renderClueDetail = () => {
    if (!selectedCategory) return;

    if (!selectedClue) {
      return (
        <Center flexGrow="1" minHeight="0" overflowY="scroll" mb={1}>
          <VStack 
            w="100%"
            maxW="540px"
            p={{ base: 3, md: 5 }} 
            spacing={3} 
            shadow="md" 
            borderWidth="1px" 
            borderRadius={4} 
          >
            <Image
              boxSize="100%"
              src=""
              bg="gray.100"
            />
            <Heading as="h3" size={isAboveSm ? "lg" : "md"}>
              請選擇一個線索
            </Heading>
          </VStack>
        </Center>
      )
    }

    const targetClue = clueMap[selectedClue];
    const { title, id, fileType, mediaSrc, ytId, isDecodable, isDiggable, isDeliverForbidden, isUsable, content, type } = targetClue;
    const hasDiggedClueIdsInStorage = window.localStorage.getItem("hasDiggedClueIds");
    const hasDiggedClueIdsArray = hasDiggedClueIdsInStorage ? JSON.parse(hasDiggedClueIdsInStorage) : [];
    const hasDigged = hasDiggedClueIdsArray.indexOf(selectedClue) > -1;
    const hasUsedClueIdsInStorage = window.localStorage.getItem("hasUsedClueIds");
    const hasUsedClueIdsArray = hasUsedClueIdsInStorage ? JSON.parse(hasUsedClueIdsInStorage) : [];
    const hasUsed = hasUsedClueIdsArray.indexOf(selectedClue) > -1;
    const hasCollected = collectionIdList.indexOf(selectedClue) > -1;
    const isCategoryCollection = selectedCategory === MY_COLLECTION;
    const isShowImage = (!fileType || fileType === "image") && mediaSrc !== "";
    const isShowAudio = fileType === "audio" && mediaSrc !== "";
    const isShowVideo = fileType === "video" && mediaSrc !== "";
    const isShowYoutube = fileType === "youtube" && ytId !== "";
    const newContent = getContentWithKeyword(getHighlightContent(content, id), findKeyword);

    return (
      <Flex 
        flexGrow="1" 
        minWidth="0"
        minHeight="0"
        align="flex-start" 
        justify="center" 
        overflowY="scroll" 
        mb={2} 
        className={'invisible-scrollbar'}
      >
        <VStack 
          w="100%"
          maxW="540px"
          p={{ base: 3, md: 5 }} 
          spacing={1} 
          justify="space-between"
          shadow="md" 
          borderWidth="1px" 
          borderRadius={4} 
        >
          {(isShowImage || isShowAudio || isShowVideo || isShowYoutube) && (
            <Box w="100%">
              {isShowImage && mediaSrc && (
                <Image
                  boxSize="100%"
                  fit="contain"
                  src={mediaSrc}
                  alt={title}
                />
              )}
              {isShowAudio && <audio src={mediaSrc} controls style={{ margin: "auto "}} />}
              {isShowVideo && <video src={mediaSrc} controls />}
              {isShowYoutube && (
                <iframe 
                  id="ytplayer" 
                  ref={ytRef}
                  type="text/html" 
                  width="500" 
                  height="281"
                  src={`https://www.youtube-nocookie.com/embed/${ytId}?rel=0&cc_load_policy=1&fs=1&color=white&iv_load_policy=3`}
                  frameBorder="0" 
                  allowFullScreen
                />
              )}
            </Box>
          )}
          <Heading as="h3" size={isAboveSm ? "lg" : "md"} align="center">
            {title || "請選擇一個線索進行調查"}
            {type === "PUBLIC" && (
              <Badge ml={3} fontSize="0.6em" colorScheme="green">
                已公開
              </Badge>
            )}
          </Heading>
          {content && (
            <p
              className={`room-clue-content`} 
              dangerouslySetInnerHTML={createMarkup(newContent)} 
              onMouseUp={handleTextSelect} 
            />
          )}
          <VStack w="100%">
            <Divider />
            <ButtonGroup variant="solid" spacing="6">
              {isUsable && !hasUsed && (
                <Button
                  rightIcon={<RiHammerLine />} 
                  colorScheme="orange" 
                  size={isAboveSm ? "md" : "sm"}
                  borderRadius={20}
                  onClick={() => handleUse(id)}
                  isDisabled={isLoading}
                >
                  使用
                </Button>
              )}
              {isDecodable && !hasDigged && (
                <Button
                  rightIcon={<FaSearchengin />} 
                  colorScheme="orange" 
                  size={isAboveSm ? "md" : "sm"}
                  borderRadius={20}
                  onClick={() => onDecode('decode', id)}
                  isDisabled={isLoading || isForbidden}
                >
                  解碼
                </Button>
              )}
              {isDiggable && !hasDigged && (
                <Button
                  rightIcon={<RiFocus3Line />} 
                  colorScheme="purple" 
                  size={isAboveSm ? "md" : "sm"}
                  borderRadius={20}
                  onClick={() => onDig(id)}
                  isLoading={isLoading}
                >
                  深入
                </Button>
              )}
              {(type !== "PUBLIC" && !isDeliverForbidden) && (
                <Button
                  rightIcon={<BsFillChatDotsFill />} 
                  colorScheme="green" 
                  size={isAboveSm ? "md" : "sm"}
                  borderRadius={20}
                  onClick={() => onDeliver(id)}
                  isDisabled={isLoading}
                >
                  分享
                </Button>
              )}
              {(!hasCollected && !hasCollected) && (
                <Button
                  rightIcon={<FaStar />} 
                  colorScheme="yellow"
                  size={isAboveSm ? "md" : "sm"}
                  borderRadius={20}
                  onClick={() => handleCollect("ADD", id)}
                >
                  收藏
                </Button>
              )}
              {(isCategoryCollection && hasCollected) && (
                <Button
                  rightIcon={<FaRegStar/>} 
                  colorScheme="yellow"
                  size={isAboveSm ? "md" : "sm"}
                  borderRadius={20}
                  onClick={() => handleCollect("REMOVE", id)}
                >
                  移除
                </Button>
              )}
            </ButtonGroup>
          </VStack>
        </VStack>
      </Flex>
    )
  };

  const renderClueGrid = () => {
    if (!selectedCategory) return;

    const hasReadClueIdsInStorage = window.localStorage.getItem("hasReadClueIds");
    const hasReadClueIdsArray = hasReadClueIdsInStorage ? JSON.parse(hasReadClueIdsInStorage) : [];

    const idList = selectedCategory === MY_COLLECTION
      ? collectionIdList
      : clueIdListInSelectedCategory
      ;

    if (isAboveSm) {
      return (
        <VStack 
          h="100%"
          pl={2}
          pr={4} 
          spacing={1} 
          zIndex="10" 
          bg={bgColor} 
          borderRight="1px solid #E2E8F0"
          overflowY="scroll"
        >
          {idList.map(clueId => {          
            const { title, shorthand, mediaSrc, id } = clueMap[clueId];
            const hasRead = hasReadClueIdsArray.indexOf(id) > -1;
  
            return (
              <Box 
                w="96px"
                px={1}
                py={2}
                borderWidth="2px"
                borderColor={id === selectedClue ? "#1A94DA" : "transparent"}
                className={hasRead ? "" : "clue--unread"}
                onClick={() => onSelectClue(id)} 
                key={`ch-${id}`}
              >
                <Image 
                  mx="auto" 
                  mb={1} 
                  boxSize={{ base: "32px", sm: "40px", md: "48px" }}
                  objectFit="cover" 
                  name={title} 
                  src={mediaSrc || DEFAULT_CLUE_THUMB_IMAGE_PATH}  
                />
                <Text align="center" isTruncated>
                  {shorthand || title}
                </Text>
              </Box>
            )
          })}
        </VStack>
      )
    } else {
      return (
        <HStack 
          minH="72px"
          pt={2} 
          spacing={1} 
          justify="flex-start" 
          alignItems="top" 
          wrap="nowrap" 
          zIndex="10" 
          bg={bgColor} 
          borderTop="1px solid #E2E8F0"
          overflowX="scroll"
        >
          {idList.map(clueId => {          
            const { title, shorthand, mediaSrc, id } = clueMap[clueId];
            const hasRead = hasReadClueIdsArray.indexOf(id) > -1;
  
            return (
              <Box 
                minW="60px"
                maxW="60px"
                p={1}
                borderWidth="2px"
                borderColor={id === selectedClue ? "#1A94DA" : "transparent"}
                className={hasRead ? "" : "clue--unread"}
                onClick={() => onSelectClue(id)} 
                key={`ch-${id}`}
              >
                <Image 
                  mx="auto" 
                  mb={1} 
                  boxSize="32px"
                  objectFit="cover" 
                  name={title} 
                  src={mediaSrc || DEFAULT_CLUE_THUMB_IMAGE_PATH}  
                />
                <Text 
                  align="center" 
                  isTruncated
                  fontSize="0.8rem"
                >
                  {shorthand || title}
                </Text>
              </Box>
            )
          })}
        </HStack>
      )
    }
  };

  const renderHighlighBox = () => {

    return (
      <Button
        pos="fixed"
        left={popoverPostion[0]}
        top={popoverPostion[1]}
        px={2}
        py={1}
        borderRadius="4px"
        transform="translateY(-150%)"
        variant="solid"
        colorScheme="yellow"
        leftIcon={<RiMarkPenFill />}
        onClick={handleHighlightSave}
      >
        畫重點
      </Button>
    )
  };

  return (
    <Flex w="100%" h="100%" py={1} pos="relative" >
      {isBasicInfoReady &&  renderCategoryList()}
      {selectedCategory ? (
        <Flex flexGrow="1" minWidth="0" h="100%" flexDirection={isAboveSm ? "row" : "column-reverse"}>
          {selectedCategory && renderClueGrid()}
          {selectedCategory && renderClueDetail()}
        </Flex>
      ) : (
        <Center mt={10}>
          <Text fontSize={isAboveSm ? "2xl" : "lg"}>
            {clueIdList.length ? "請選擇一個線索" : "尚未取得任何線索！"}
          </Text>
        </Center>
      )}
      {findKeyword && (
        <Box pos="fixed" left={{ base: "5em", sm: "6.5em", md: "7.5em" }} top={4} zIndex="20" w="200px" shadow="md">
          <KeywordInput findKeyword={findKeyword} setFindKeyword={setFindKeyword} />
        </Box>
      )}
      {isShowPopover && renderHighlighBox()}
      {isShowConfirm && (
        <ConfirmUseDialog
          onConfirm={handleConfirmUse}
          onCancel={handleCancelUse}
          shorthand={clueMap[useClueId].shorthand}
        />
      )}
    </Flex>
  )
};

const ConfirmUseDialog = props => {
  const { onCancel, onConfirm, shorthand } = props;

  return (
    <Modal 
      isOpen={true} 
      onClose={onCancel}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>使用線索</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          確定要使用【 <Text as="span" color="red.500" fontSize="1.6em" >{shorthand}</Text> 】嗎？
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button 
              borderRadius={20}
              variant="outline" 
              onClick={onCancel} 
            >
              取消
            </Button>
            <Button 
              rightIcon={<RiHammerLine />}   
              colorScheme="orange"
              borderRadius={20}
              variant="solid" 
              onClick={onConfirm} 
            >
              確定
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

export default Clues;