import React, { useState } from "react";
import {
  Text,
  Avatar,
  Flex,
  Stack,
  HStack,
  Button,
  Box,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import { FcCheckmark } from 'react-icons/fc';
import { MdPersonPin } from 'react-icons/md';
import { generateMap, createMarkup } from '../../utils'

const CharactersChoiceWindow = props => {
  const { isBasicInfoReady = false, staticData, characterPlayerMap, playerId = '', onSelect } = props;
  const { charactersList } = staticData;

  const [hasConfirmed, setHasConfirmed] = useState(false);

  const chosenCharacterIdList = Object.keys(characterPlayerMap).filter(cId => characterPlayerMap[cId] !== "");
  const hasChosen = Object.values(characterPlayerMap).indexOf(playerId) > -1;

  const handleSelect = characterId => {
    if (hasConfirmed) return;

    if (chosenCharacterIdList.includes(characterId)) {
      return;
    } else {
      onSelect(characterId)
    }
  };

  const handleConfirmToggle = () => {
    setHasConfirmed(!hasConfirmed);
  };

  return (
    <Modal 
      isOpen={isBasicInfoReady} 
      isCentered 
      size="3xl"
      motionPreset="scale"
      onClose={() => {}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize={{ base: "xl", sm: "2xl", md: "3xl" }} fontWeight="bold" align="center">請選擇你今天要扮演的角色</Text>
        </ModalHeader>
        <ModalBody bg={hasConfirmed ? "rgba(0, 0, 0, 0.05)" : ""}>
          {(isBasicInfoReady && charactersList) && charactersList.map(ele => {
            const { name, mediaSrc, id, intro } = ele;
            const hasBeenChosen = chosenCharacterIdList.includes(id);
            const chosenByThisPlayer = characterPlayerMap[id] === playerId;

            return (
              <Flex 
                mb={3} 
                p={3} 
                justify="space-between" 
                align="center" 
                onClick={() => handleSelect(id)} 
                _hover={{ cursor: hasConfirmed || (hasBeenChosen && !chosenByThisPlayer) ? "not-allowed" : "pointer" }}
                key={`ch-${id}`}
              >
                <Flex align="center">
                  <Avatar size="lg" name={name} src={mediaSrc} />
                  <Box ml={5}>
                    <Text fontSize="xl" fontWeight="bold">
                      {name}
                    </Text>
                    <Text fontSize="md" dangerouslySetInnerHTML={createMarkup(intro)} />
                  </Box>
                </Flex>
                <Box>
                  {hasBeenChosen
                    ? chosenByThisPlayer
                      ? <Icon as={FcCheckmark} w={10} h={10}/>
                      : <Icon as={MdPersonPin} w={10} h={10}/>
                    : null
                  }
                </Box>
              </Flex>
            )
          })}
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Stack w="100%">
            <HStack spacing={10} justifyContent="center">
              <Button 
                variant="outline" 
                size="lg" 
                onClick={() => handleSelect('')}
                disabled={hasConfirmed || !hasChosen}
              >
                觀望一下
              </Button>
              <Button 
                colorScheme="green" 
                variant={hasConfirmed ? "solid" : "outline"}
                size="lg" 
                onClick={handleConfirmToggle}
                disabled={!hasChosen}
              >
                {hasConfirmed ? "我要換角" : "我選好了"}
              </Button>
            </HStack>
            <Text textAlign="center" fontSize="lg">
              {hasConfirmed ? "想重選請按「我要換角」；選定後請靜待主持人指示 ..." : "選擇「觀望一下」可以取消選取；選定請按「我選好了」"}
            </Text>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

  export default CharactersChoiceWindow;