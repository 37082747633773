import axios from "axios";
import { auth } from './firebase';

/** PROD */
export const STORAGE_BASE = "https://storage.googleapis.com/larp-bar.appspot.com";
const API_BASE = "https://api-pyupsq3riq-df.a.run.app";

/** DEV */
// export const STORAGE_BASE = "https://storage.googleapis.com/larp-bar-dev.appspot.com";
// const API_BASE = "https://api-csu5u63yoq-df.a.run.app";

/** PROJECT */
// export const STORAGE_BASE = "https://storage.googleapis.com/larp-bar-project.appspot.com";
// const API_BASE = "https://api-acbnj4ke4a-df.a.run.app";

const request = (path, params) => {

  return axios.post(`${API_BASE}${path}`, {
    uid: auth.currentUser ? auth.currentUser.uid : null,
    ...params
  });
}

/** 1.1 */
export const getHostData = params => request(`/host/getHostData`, params);
/** 1.14 */
export const clearRoom = params => request(`/host/clearRoom`, params);
/** 1.17 */
export const transferPlayerData = params => request(`/host/transferPlayerData`, params);
/** 2.1 */
export const joinRoom = params => request(`/room/joinRoom`, params);
/** 2.2 */
export const getScenarioBasicInfo = params => request(`/room/getScenarioBasicInfo`, params);
/** 2.6 */
export const getScene = params => request(`/room/getScene`, params);
/** 2.8 */
export const getClueSteps = params => request(`/room/getClueSteps`, params);
/** 2.9^ */
export const getClue = params => request(`/room/getClue`, params);
/** 2.10 */
export const postExploreClue = params => request(`/room/postExploreClue`, params);
/** 2.11 */
export const postDigClue = params => request(`/room/postDigClue`, params);
/** 2.13 */
export const getVote = params => request(`/room/getVote`, params);
/** 2.17 */
export const getRoomId = params => request(`/room/getRoomId`, params);
/** 3.2 */
export const getGamesReport = params => request(`/admin/getGamesReport`, params);
/** 3.3 */
export const createUser = params => request(`/admin/createUser`, params);
/** 3.4 */
export const getPermission = params => request(`/admin/getPermission`, params);
/** 3.5 */
export const getUserList = params => request(`/admin/getUserList`, params);
/** 3.6 */
export const updatePermission = params => request(`/admin/updatePermission`, params);
/** 3.7 */
export const createRoom = params => request(`/admin/createRoom`, params);
/** 3.8 */
export const getRoomList = params => request(`/admin/getRoomList`, params);
/** 3.10 */
export const closeRoom = params => request(`/admin/closeRoom`, params);
/** 3.11 */
export const getEditScenarioData = params => request(`/admin/getEditScenarioData`, params);
/** 3.12 */
export const postNewEditClue = params => request(`/admin/postNewEditClue`, params);
/** 3.13 */
export const putEditClue = params => request(`/admin/putEditClue`, params);
/** 3.14 */
export const deleteEditClue = params => request(`/admin/deleteEditClue`, params);
/** 3.15 */
export const putEditCharacter = params => request(`/admin/putEditCharacter`, params);
/** 3.16 */
export const putEditCharacterScript = params => request(`/admin/putEditCharacterScript`, params);
/** 3.17 */
export const getEditCharacterScript = params => request(`/admin/getEditCharacterScript`, params);