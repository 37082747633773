import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import { getClue } from './api';
require('dotenv').config()

const ROOM_BASE_LENGTH = 7 + 20;

let fb, rt, st, auth, googleSignIn, roomBase;

/**
 * @param {string} roomId embed 模式下可以沒有 roomId
 */
const initFb = (roomId) => {
  // console.log(`ROOM ID: ${roomId}`)
  /** PROD CONFIGS */
  const fbConfig = {
    apiKey: "AIzaSyCD_Waw_yb0KZvJ-yCJqbJE9Z7gKXfENSQ",
    authDomain: "larp-bar.firebaseapp.com",
    databaseURL: "https://larp-bar-default-rtdb.firebaseio.com",
    projectId: "larp-bar",
    storageBucket: "larp-bar.appspot.com",
    messagingSenderId: "140821021638",
    appId: "1:140821021638:web:a23462cacd9440995aa8c7",
    measurementId: "G-48P245QSL1"
  }

  /** DEV CONFIGS */
  // const fbConfig = {
  //   apiKey: "AIzaSyC05mPZwYkcBpf-uthQACar2-nVLSLZh08",
  //   authDomain: "larp-bar-dev.firebaseapp.com",
  //   projectId: "larp-bar-dev",
  //   storageBucket: "larp-bar-dev.appspot.com",
  //   messagingSenderId: "209004123259",
  //   databaseURL: "https://larp-bar-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
  //   appId: "1:209004123259:web:e8c30fedc1b901e498fede",
  //   measurementId: "G-9KY4B4T4RB"
  // };

  /** PRJ CONFIGS */
  // const fbConfig = {
  //   apiKey: "AIzaSyDrlkT9ZRiFm8Cbrlp_ffHfHUvthqCOqY0",
  //   authDomain: "larp-bar-project.firebaseapp.com",
  //   projectId: "larp-bar-project",
  //   storageBucket: "larp-bar-project.appspot.com",
  //   messagingSenderId: "946807125764",
  //   databaseURL: "https://larp-bar-project-default-rtdb.firebaseio.com/",
  //   appId: "1:946807125764:web:4adf3d5d14a75e18bac251",
  //   measurementId: "G-D49NY2DVS0"
  // };

  if (roomId) roomBase = `/rooms/${roomId}`;

  try {
    fb = !firebase.apps.length ? firebase.initializeApp(fbConfig) : firebase.app();
    rt = fb.database();
    st = fb.storage();
    auth = fb.auth();
    auth.useDeviceLanguage();

    const provider = new firebase.auth.GoogleAuthProvider();
    googleSignIn = () => auth.signInWithRedirect(provider);

    return new Promise((resolve, reject) => {
      if (!!fb) {
        resolve()
      } else {
        reject()
      }
    });
  } catch (err) {
    console.log(err)
  }
};

/**
 * 0.1
 */
const getRoomConfigs = () => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;
  // TODO: roomBase 不存在的處理
  return rt.ref(`${roomBase}/configs`).once('value');
};

/**
 * 0.2
 */
const getRoomStatus = () => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  return rt.ref(`${roomBase}/status`);
};

/**
 * 1.2
 */
const getPlayerNameDict = () => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  return rt.ref(`${roomBase}/playerNameDict`)
};

/**
 * 1.3
 * @param {string} value
 */
const setIsShowChoices = (value) => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  rt.ref(`${roomBase}/status`).update({
    isShowChoices: value === "ON" ? true : false
  })
};

/**
 * 1.4, 2.4
 */
const getPlayerCharacterDict = () => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;
  
  return rt.ref(`${roomBase}/playerCharacterDict`)
};

/**
 * 1.5
 */
const setPlayers = () => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;
  
  let count = 0;

  return new Promise((res, rej) => {
    rt.ref(`${roomBase}/playerCharacterDict`).once('value').then(snapshot => {
      if (snapshot.exists()) {
        const length = snapshot.numChildren();

        snapshot.forEach((snap) => {
          const characterId = snap.key;
          const playerId = snap.val();

          if (playerId) {
            rt.ref(`${roomBase}/players`).child(playerId).update({
              characterId,
              isReady: false
            });
          }
  
          count += 1;

          if (count === length) {
            rt.ref(`${roomBase}/status`).update({
              hasStarted: true
            })
            
            res({ isSuccess: true });
          }
        });
      }
    })
  });
} 

/**
 * 1.6
 */
 const getPlayersStatus = () => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  return rt.ref(`${roomBase}/players`)
};

/**
 * 1.7
 * @param {string} sceneId 
 * @param {int}    timestamp
 * @param {int}    explorableCount
 */
const setScene = (sceneId, timestamp, explorableCount) => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  return new Promise((res, rej) => {
    rt.ref(`${roomBase}/status`).update({
      currentScene: sceneId
    });
  
    rt.ref(`${roomBase}/record`).update({
      [sceneId]: timestamp
    });
  
    rt.ref(`${roomBase}/players`).once('value').then(snapshot => {
      snapshot.forEach(snap => {
        rt.ref(`${roomBase}/players`).child(snap.key).update({
          isReady: false,
          explorableCount: explorableCount
        })
      });
    });

    // TOFIX: 不論上面是否順利完成都會回成功
    res({
      isSuccess: true
    });
  });
}

/**
 * 1.8
 */
 const getScenesTime = () => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  return rt.ref(`${roomBase}/record`)
};

/**
 * 1.9
 * @param {string} playerId 
 */
const setActionPlayer = playerId => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  rt.ref(`${roomBase}/status`).update({
    currentActionPlayerId: playerId
  })
}

/**
 * 1.10, 2.12
 * @param {array} clueList  [{ clueId, targetId, type }, ...]
 * @param {object} log      
 */
const setCluesStatus = (clueList, log) => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  clueList.forEach(clueObj => {
    const { clueId, targetId, type } = clueObj;
    // 若找不到 targetId 會直接變成 /players/clues ，但理論上全員到齊開始之後不應該發生這個問題
    rt.ref(`${roomBase}/players/${targetId}/clues`).update({
      [clueId]: type
    })
  })

  if (log.timestamp && log.description) {
    rt.ref(`${roomBase}/log/`).update({
      [log.timestamp]: log.description
    })
  }
}

/**
 * 1.11
 */
 const getVoteStatus = () => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  return rt.ref(`${roomBase}/votes`);
};

/**
 * 1.12
 * @param {array} endingList [{ playerId, branchKey }, ...]
 */
const setEndingStatus = endingList => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  return new Promise((res, rej) => {
    endingList.forEach(endingObj => {
      const { playerId, branchKey } = endingObj;
      rt.ref(`${roomBase}/players/${playerId}/`).update({
        endingBranchKey: branchKey
      })
    })

    res({ 
      isSuccess: true
    })
  })
}

/**
 * 1.13
 * @param {boolean} status 
 * @param {string}  message 
 */
const setNoticeStatus = (status, message = "") => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  if (status === "ON") {
    rt.ref(`${roomBase}/status`).update({
      isShowNotice: true,
      noticeMessage: message
    })
  } else {
    rt.ref(`${roomBase}/status`).update({
      isShowNotice: false,
    })
  }
};

/**
 * 1.16
 * @param {object} 
 */
const setChooseCharacterBatch = item => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;
  // TODO: 重寫此段
  const characterIdList = Object.keys(item);

  characterIdList.forEach(characterId => {
    const playerId = item[characterId];

    rt.ref(`${roomBase}/playerCharacterDict`).update({
      [characterId]: playerId,
    })
  })
};

/**
 * 1.19
 * @param {string}  playerId
 * @param {number}  newCount
 */
 const setNewExplorableCount = (playerId, newCount) => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  return new Promise((res, rej) => {  
    rt.ref(`${roomBase}/players/${playerId}`).update({
      explorableCount: newCount,
    })

    res({ isSuccess: true })
  })
};

/**
 * 1.20
 */
const getActionlogs = () => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  return rt.ref(`${roomBase}/log`)
};

/**
 * 1.21
 */
const setActionLog = (log) => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  if (log.timestamp && log.description) {
    rt.ref(`${roomBase}/log`).update({
      [log.timestamp]: log.description
    })
  }
};

/**
 * 2.4
 * @param {string} playerId 
 * @param {string} characterId 
 */
const setChooseCharacter = (playerId, characterId) => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  const ref = `${roomBase}/playerCharacterDict`;
  rt.ref(ref).once('value')
    .then(snapshot => {
      if (snapshot.exists()) {
        snapshot.forEach(snap => {
          // Remove the playerId from the selected character
          if(snap.val() === playerId) {

            rt.ref(ref).update({
              [snap.key]: '',
            })
          }
        })

        if (characterId) {
          rt.ref(ref).update({
            [characterId]: playerId,
          })
        }
      } else {
        rt.ref(ref).update({
          [characterId]: playerId,
        })
      }
    })
};

/**
 * 2.6
 * @param {string} playerId 
 */
 const getPlayerOwnStatus = playerId => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  // TODO: 改為 promise 並回傳錯誤
  return rt.ref(`${roomBase}/players/${playerId}`)
};

/**
 * 2.8
 * @param {string} playerId 
 */
const setReadyStatus = (playerId, status) => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  rt.ref(`${roomBase}/players/${playerId}`).update({
    isReady: status === "ON",
  })
};

/**
 * 2.9
 * Due to Firestore query restrictions, split queries
 * @param {string}  scenarioId 
 * @param {array}   clueIds 
 */
const getClues = async ({ scenarioId, clueIds }) => {
  let batch = [];
  let i, j, chunks, chunk = 10;
  for (i = 0, j = clueIds.length; i < j; i += chunk) {
    chunks = clueIds.slice(i, i+chunk);
    batch.push(chunks);
  }
  const batchResult = batch.map(idArray => {
    return new Promise((res, rej) => {
      getClue({ scenarioId, clueIds: idArray })
        .then(response => {
          res(response.data);
        }).catch(err => {
          rej(err)
        })
    })
  });

  return Promise.all(batchResult);
};

/**
 * 2.14
 * @param {string} playerId 
 * @param {string} sceneId 
 * @param {number} number
 * @param {number} option
 * @param {string} value 
 */
const setVoteValue = ({ playerId, sceneId, number, option, value }) => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  rt.ref(`${roomBase}/votes/${sceneId}/${number}/${playerId}`).update({
    [option]: value,
  })
};

/**
 * 2.15
 * @param {string} clueId 
 */
const getClueHolders = clueId => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  return new Promise((res, rej) => {
    rt.ref(`${roomBase}/players`).once('value').then(snapshot => {
      let holderCount = 0;

      if (snapshot.exists()) {
        const length = snapshot.numChildren();
        let count = 0;

        snapshot.forEach(snap => {
          if (snap.hasChild('clues') && snap.hasChild(`clues/${clueId}`)) {
            holderCount += 1;
          }
          count +=1;

          if (count === length) {
            res({ holderCount })
          }
        })
      } else {
        res({ holderCount })
      }
    })
  });
};

/**
 * 2.16
 * @param {string}  playerId
 */
const setMinusExplorableCount = (playerId) => {
  if (roomBase.length < ROOM_BASE_LENGTH) return;

  return new Promise((res, rej) => {
    rt.ref(`${roomBase}/players/${playerId}`).once('value').then(snapshot => {
      if (snapshot.child('explorableCount').exists()) {
        const currentValue = snapshot.child('explorableCount').val();
  
        rt.ref(`${roomBase}/players/${playerId}`).update({
          explorableCount: currentValue - 1,
        })

        res({ isSuccess: true })
      }
    });
  })
};

export {
  initFb,
  st,
  auth,
  googleSignIn,
  getRoomConfigs,         // 0.1
  getRoomStatus,          // 0.2
  getPlayerNameDict,      // 1.2
  setIsShowChoices,       // 1.3
  getPlayerCharacterDict, // 1.4
  setPlayers,             // 1.5
  getPlayersStatus,       // 1.6
  setScene,               // 1.7
  getScenesTime,          // 1.8
  setActionPlayer,        // 1.9
  setCluesStatus,         // 1.10
  getVoteStatus,          // 1.11
  setEndingStatus,        // 1.12
  setNoticeStatus,        // 1.13
  setChooseCharacterBatch,  // 1.16
  setNewExplorableCount,  // 1.19
  getActionlogs,          // 1.20
  setActionLog,           // 1.21
  setChooseCharacter,     // 2.3
  getPlayerOwnStatus,     // 2.5
  setReadyStatus,         // 2.7
  getClues,               // 2.9
  setVoteValue,           // 2.14
  getClueHolders,         // 2.15
  setMinusExplorableCount,  // 2.16
};